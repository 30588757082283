import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images
import img1 from "../../images/products/Industrial/Disk Mill/(6205)(6204)(233) FFC DISK MILL ONLY (IDEAL).jpg"
import img2 from "../../images/products/Industrial/Disk Mill/(6323)(1725) DISK MILL FULL SET (IDEAL).jpg"
import img3 from "../../images/products/Industrial/Disk Mill/Gear Box.jpg"

import img4 from "../../images/products/Industrial/Electric/(1296)(1297) ELECTRIC HOIST.jpg"
import img5 from "../../images/products/Industrial/Electric/(1993)(2739) ELECTRIC HOIST-500X1000KG 30M 2.2KW YALE.jpg"
import img6 from "../../images/products/Industrial/Electric/(2153)(2154) ELECTRIC HOIST CABLE CARRIER.jpg"
import img7 from "../../images/products/Industrial/Electric/(2861) ELECTRIC VIBRAT MOTOR-ATTACHED TYPE RED.jpg"
import img8 from "../../images/products/Industrial/Electric/(4787) ELECTRIC FORKER - MACHINE ONLY.jpg"

import img9 from "../../images/products/Industrial/Floor Polish/(1656) FLOOR POLISHING MACHINE (ELECTRIC).jpg"
import img10 from "../../images/products/Industrial/Floor Polish/(2678) FLOOR CUTTING MACHINE (220V).jpg"

import img11 from "../../images/products/Industrial/Generator/(849) Generator Jq-1500 (15l) Normal Start.jpg"
import img12 from "../../images/products/Industrial/Generator/(977) GENERATOR 5KV OPEN TYPE.jpg"
import img13 from "../../images/products/Industrial/Generator/(2081) GENERATOR 5KV WITH ATS.jpg"
import img14 from "../../images/products/Industrial/Generator/(2082) GENERATOR JQ-4000 (15L) KEY START.jpg"
import img15 from "../../images/products/Industrial/Generator/(2959) Juqiang-7500 Generator Key Start (Petrol Open Type).jpg"

import img16 from "../../images/products/Industrial/Grease Bucket/(3013) GREASE BUCKET 40KG (PNEUMATIC) LARGE.jpg"
import img17 from "../../images/products/Industrial/Grease Bucket/(854) GREASE BUCKET 5KG (MANUAL).jpg"
import img18 from "../../images/products/Industrial/Grease Bucket/(855) GREASE BUCKET 12KG (PNEUMATIC).jpg"

import img19 from "../../images/products/Industrial/High Pressure/(768) HPRESSURE (QX-280)-HEAD FULL SET.jpg"
import img20 from "../../images/products/Industrial/High Pressure/(1271) HPRESSURE (QX-280)-MOTOR.jpg"
import img21 from "../../images/products/Industrial/High Pressure/(2151) HPRESSURE (QX-550)-HEAD ONLY.jpg"
import img22 from "../../images/products/Industrial/High Pressure/(2349) High Pressure Machine Intimax.jpg"

import img23 from "../../images/products/Industrial/Industrial/(120) Rb25 Bar Bender(220v) Single Phase 0.jpg"
import img24 from "../../images/products/Industrial/Industrial/(714) Gw50 Steel Bar Bender(380v 3 Phase).jpg"
import img25 from "../../images/products/Industrial/Industrial/(1089)(1380) DENT PULLER MACHINE-220V.jpg"
import img26 from "../../images/products/Industrial/Industrial/(1222)(1236) Bench Drill Machine.jpg"
import img27 from "../../images/products/Industrial/Industrial/(1300) SPOT WELDER-SINGLE PHASE (DN-100E).jpg"
import img28 from "../../images/products/Industrial/Industrial/(1367) Column spot welder 220v-16cm.jpg"
import img29 from "../../images/products/Industrial/Industrial/(1985) Pipe Bender Machine-multifunction (220v).jpg"
import img30 from "../../images/products/Industrial/Industrial/(2140) EB-260 ENGINE BLOWER (HAND).jpg"
import img31 from "../../images/products/Industrial/Industrial/(2621) BAG CLOSER MACHINE-TEKO.jpg"
import img32 from "../../images/products/Industrial/Industrial/(2665) NITROGEN MACHINE-AIR FILLING.jpg"
import img33 from "../../images/products/Industrial/Industrial/(2669) DENT PULLER-MINI (ELECTRIC).jpg"
import img34 from "../../images/products/Industrial/Industrial/(2717) GASOLINE HAMMER DRILL-INTIMAX (0558).jpg"

import img35 from "../../images/products/Industrial/Industrial Motor/5077) PEM-IDEAL INDUSTRIAL MOTOR 03HP 2PWWORK.jpg"
import img36 from "../../images/products/Industrial/Industrial Motor/PEM-IDEAL INDUSTRIAL MOTOR (3P).jpg"
import img37 from "../../images/products/Industrial/Industrial Motor/PEM-IDEAL INDUSTRIAL MOTOR.jpg"

import img38 from "../../images/products/Industrial/Jack/(185) Ramp Jack-bike.jpg"
import img39 from "../../images/products/Industrial/Jack/(636) TROLLEY JACK VEIBER 4T.jpg"
import img40 from "../../images/products/Industrial/Jack/(1775) Hybrid Jack (Green).jpg"
import img41 from "../../images/products/Industrial/Jack/(2373) Two post hydraulic lift.jpg"
import img42 from "../../images/products/Industrial/Jack/(2554) TROLLEY JACK-2.5TON (HYDRAULIC).jpg"
import img43 from "../../images/products/Industrial/Jack/(2556) PALLET JACK-2TON.jpg"
import img44 from "../../images/products/Industrial/Jack/(2677) Shop Crane (2Ton).jpg"
import img45 from "../../images/products/Industrial/Jack/(3014) Pneumatic Jack-50ton (L-type) Red.jpg"

import img46 from "../../images/products/Industrial/Tile/(2226) Tile Cutter -0900mm Super Pro Hd (Green).jpg"
import img47 from "../../images/products/Industrial/Tile/(3690) Tile Cutter-0600mm Taiwan Hd.jpg"
import img48 from "../../images/products/Industrial/Tile/(5086) Tile Cutter-0800mm Taiwan Hd.jpg"
import img49 from "../../images/products/Industrial/Tile/(5087) Tile Cutter-1200mm Taiwan Hd.jpg"
import img50 from "../../images/products/Industrial/Tile/(6699) Tile Router-800mm Topway 3 In 1 Wo Grinder.jpg"
import img51 from "../../images/products/Industrial/Tile/TILE CUTTER - (ROD TYPE).jpg"
import img52 from "../../images/products/Industrial/Tile/TILE CUTTER - SINGLE BAR.jpg"

import img53 from "../../images/products/Industrial/Tyre/(2676) Tyre Changer Machine 24.jpg"

import img54 from "../../images/products/Industrial/Vacum Cleaner Machine/(584) Vacum Cleaner Machine 100L (3Motors).jpg"
import img55 from "../../images/products/Industrial/Vacum Cleaner Machine/(1413)(2597) VACUUM CLEANER MACHINE.jpg"

import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"


const compressor = [
  {
    img:img1,
    name:"(6205)(6204)(233) FFC DISK MILL ONLY (IDEAL)"
  },
  {
    img:img2,
    name:"(6323)(1725) DISK MILL FULL SET (IDEAL)"
  },
  {
    img:img3,
    name:"Gear Box"
  },
  {
    img:img4,
    name:"(1296)(1297) ELECTRIC HOIST"
  },
  {
    img:img5,
    name:"(1993)(2739) ELECTRIC HOIST-500X1000KG 30M 2.2KW YALE"
  },{
    img:img6,
    name:"(2153)(2154) ELECTRIC HOIST CABLE CARRIER"
  },
  {
    img:img7,
    name:"(2861) ELECTRIC VIBRAT MOTOR-ATTACHED TYPE RED"
  },
  {
    img:img8,
    name:"(4787) ELECTRIC FORKER - MACHINE ONLY"
  },{
    img:img9,
    name:"(1656) FLOOR POLISHING MACHINE (ELECTRIC)"
  },
  {
    img:img10,
    name:"(2678) FLOOR CUTTING MACHINE (220V)"
  },
  {
    img:img11,
    name:"(849) Generator Jq-1500 (15l) Normal Start"
  },
  {
    img:img12,
    name:"(977) GENERATOR 5KV OPEN TYPE"
  },
  {
    img:img13,
    name:"(2081) GENERATOR 5KV WITH ATS"
  },
  {
    img:img14,
    name:"(2082) GENERATOR JQ-4000 (15L) KEY START"
  },
  {
    img:img15,
    name:"(2959) Juqiang-7500 Generator Key Start (Petrol Open Type)"
  },{
    img:img16,
    name:"(3013) GREASE BUCKET 40KG (PNEUMATIC) LARGE"
  },
  {
    img:img17,
    name:"(854) GREASE BUCKET 5KG (MANUAL)"
  },
  {
    img:img18,
    name:"(855) GREASE BUCKET 12KG (PNEUMATIC)"
  },{
    img:img19,
    name:"(768) HPRESSURE (QX-280)-HEAD FULL SET"
  },
  {
    img:img20,
    name:"(1271) HPRESSURE (QX-280)-MOTOR"
  },
// 30start
  {
    img:img21,
    name:"(2151) HPRESSURE (QX-550)-HEAD ONLY"
  },
  {
    img:img22,
    name:"(2349) High Pressure Machine Intimax"
  },
  {
    img:img23,
    name:"(120) Rb25 Bar Bender(220v) Single Phase"
  },
  {
    img:img24,
    name:"(714) Gw50 Steel Bar Bender(380v 3 Phase)"
  },
  {
    img:img25,
    name:"(1089)(1380) DENT PULLER MACHINE-220V"
  },{
    img:img26,
    name:"(1222)(1236) Bench Drill Machine"
  },
  {
    img:img27,
    name:"(1300) SPOT WELDER-SINGLE PHASE (DN-100E)"
  },
  {
    img:img28,
    name:"(1367) Column spot welder 220v-16cm"
  },{
    img:img29,
    name:"(1985) Pipe Bender Machine-multifunction (220v)"
  },
  {
    img:img30,
    name:"(2140) EB-260 ENGINE BLOWER (HAND)"
  },
// 40start
  {
    img:img31,
    name:"(2621) BAG CLOSER MACHINE-TEKO"
  },
  {
    img:img32,
    name:"(2665) NITROGEN MACHINE-AIR FILLING"
  },
  {
    img:img33,
    name:"(2669) DENT PULLER-MINI (ELECTRIC)"
  },
  {
    img:img34,
    name:"(2717) GASOLINE HAMMER DRILL-INTIMAX (0558)"
  },
  {
    img:img35,
    name:"(5077) PEM-IDEAL INDUSTRIAL MOTOR 03HP 2PWWORK"
  },{
    img:img36,
    name:"PEM-IDEAL INDUSTRIAL MOTOR (3P)"
  },
  {
    img:img37,
    name:"PEM-IDEAL INDUSTRIAL MOTOR"
  },
  {
    img:img38,
    name:"(185) Ramp Jack-bike"
  },
{
    img:img39,
    name:"(636) TROLLEY JACK VEIBER 4T"
  },{
    img:img40,
    name:"(1775) Hybrid Jack (Green)"
  },
  {
    img:img41,
    name:"(2373) Two post hydraulic lift"
  },
// 40start
  {
    img:img42,
    name:"(2554) TROLLEY JACK-2.5TON (HYDRAULIC)"
  },
  {
    img:img43,
    name:"(2556) PALLET JACK-2TON"
  },
  {
    img:img44,
    name:"(2677) Shop Crane (2Ton)"
  },
  {
    img:img45,
    name:"(3014) Pneumatic Jack-50ton (L-type) Red"
  },
  {
    img:img46,
    name:"(2226) Tile Cutter -0900mm Super Pro Hd (Green)"
  },{
    img:img47,
    name:"(3690) Tile Cutter-0600mm Taiwan Hd"
  },
  {
    img:img48,
    name:"(5086) Tile Cutter-0800mm Taiwan Hd"
  },
  {
    img:img49,
    name:"(5087) Tile Cutter-1200mm Taiwan Hd"
  },
  {
    img:img50,
    name:"(6699) Tile Router-800mm Topway 3 In 1 Wo Grinder"
  },{
    img:img51,
    name:"TILE CUTTER - (ROD TYPE)"
  },{
    img:img52,
    name:"TILE CUTTER - SINGLE BAR"
  },
  {
    img:img53,
    name:"(2676) Tyre Changer Machine 24"
  },
  {
    img:img54,
    name:"(584) Vacum Cleaner Machine 100L (3Motors)"
  },
  {
    img:img55,
    name:"(1413)(2597) VACUUM CLEANER MACHINE"
  }

]

function Industrial() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default Industrial
