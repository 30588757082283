import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images
import img1 from "../../images/products/Power Tools & Spares/Angle Grinder/(1691) Router Machine Angle Grinder 4 (Wet polisher Grinder).jpg"
import img2 from "../../images/products/Power Tools & Spares/Angle Grinder/(6491) Osuka Angle Grinder Machine 4  (Osk-9300).jpg"
import img3 from "../../images/products/Power Tools & Spares/Power tools/Core Drill Machine - Bosun (Z1z-110t).jpg"

import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"

const compressor = [
  {
    img:img1,
    name:"(1691) Router Machine Angle Grinder 4 (Wet polisher Grinder)"
  },
  {
    img:img2,
    name:"(6491) Osuka Angle Grinder Machine 4  (Osk-9300)"
  },{
    img:img3,
    name:"Core Drill Machine - Bosun (Z1z-110t)"
  },
]

function PowerTools() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a  className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default PowerTools
