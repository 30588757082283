import React from "react"
import Slider from "react-slick"
// importing images
import img1 from "../../images/products/General Hardware/Electric/(1059) Die Grinder Accessories set - 105 set 01.jpg"
import img2 from "../../images/products/General Hardware/Electric/(1446) paint zoom spray machine - portable - Copy.jpg"
import img3 from "../../images/products/General Hardware/Electric/(1948) ELECTRIC SUCKER SOLDER (BOUTH) 01.jpg"
import img4 from "../../images/products/General Hardware/Electric/(2209) ELECTRIC DIE GRINDER - PVC BOX copy 01.jpg"
import img5 from "../../images/products/General Hardware/Electric/(FE1073) Die Grinder Accessories Set 01.jpg"
import img6 from "../../images/products/General Hardware/Electric/ELECTRIC BOUTH 01.jpg"
import img7 from "../../images/products/General Hardware/Electric/ELECTRIC BOUTH0 01.jpg"

import img8 from "../../images/products/General Hardware/Galvanize/(1046) GI ANCHOR BOLT 12X SLIVE 50MM BOLT 120M 01.jpg"
import img9 from "../../images/products/General Hardware/Galvanize/GI ANCHOR BOLT 01.jpg"
import img10 from "../../images/products/General Hardware/Galvanize/GI CARRIAGE BOLT 01.jpg"

import img11 from "../../images/products/General Hardware/Glass/(476) GLASS SUCTION VACUUM CUP THREE-JAR 01.jpg"
import img12 from "../../images/products/General Hardware/Glass/(1038) GLASS SUCTION CUP SINGLE 8 HD 01.jpg"
import img13 from "../../images/products/General Hardware/Glass/(2462) GLASS SUCTION VACUUM CUP Single HD 01.jpg"
import img14 from "../../images/products/General Hardware/Glass/(2463) GLASS SUCTION VACUUM CUP DOUBLE HD 01.jpg"
import img15 from "../../images/products/General Hardware/Glass/(6464) GLASS CUTTER TOOL (OIL FEED WHEEL) 01.jpg"

import img16 from "../../images/products/General Hardware/Glue Gun/(247) GLUE GUN - 100W (GG-8) 01.jpg"
import img17 from "../../images/products/General Hardware/Glue Gun/(1126) Vieber Ratchet Plastic Pipe cutter 01.jpg"
import img18 from "../../images/products/General Hardware/Glue Gun/(2756) SILICONE GUN FRAME 01.jpg"
import img19 from "../../images/products/General Hardware/Glue Gun/(6650) Silicone Gun Frame-blue.jpg"
import img20 from "../../images/products/General Hardware/Glue Gun/(6651) Silicone Gun Frame-orange.jpg"
import img21 from "../../images/products/General Hardware/Glue Gun/GLUE GUM - TUBE ONLY 01.jpg"
import img22 from "../../images/products/General Hardware/Glue Gun/GLUE GUN - (MODEL-2) 01.jpg"
import img23 from "../../images/products/General Hardware/Glue Gun/GLUE GUN - no1 01.jpg"

import img24 from "../../images/products/General Hardware/Measuring Tape/BOKE STEEL TAPE (PROFESSIONAL) 01.jpg"
import img25 from "../../images/products/General Hardware/Measuring Tape/FIBER MEASURING TAPE PVC 01.jpg"
import img26 from "../../images/products/General Hardware/Measuring Tape/finder pro steal tape 01.jpg"
import img27 from "../../images/products/General Hardware/Measuring Tape/FINDER STEAL TAPE copy 01.jpg"
import img28 from "../../images/products/General Hardware/Measuring Tape/MEASURING TAPECLOTH MEASURING TAPE  (YUEBAO) 01.jpg"
import img29 from "../../images/products/General Hardware/Measuring Tape/MEASURING TAPEFINDER STEAL TAPE 01.jpg"
import img30 from "../../images/products/General Hardware/Measuring Tape/STANLEY STEAL TAPEcopy 01.jpg"

import img31 from "../../images/products/General Hardware/Paint/(2758) PAINT ROLLER BRUSH WITH HANDLE (VEIBER) 01.jpg"

import img32 from "../../images/products/General Hardware/Paper Cutter/(1386) PAPER CUTTER KNIFE (FIBER) 01.jpg"
import img33 from "../../images/products/General Hardware/Paper Cutter/(1387) PAPER CUTTER-BLADE (10PCS BOX) 01.jpg"
import img34 from "../../images/products/General Hardware/Paper Cutter/(2967) PAPER CUTTER KNIFE SRUNV (SR-029) HAVY 01.jpg"

import img35 from "../../images/products/General Hardware/Polythene Sealer/(291) POLYTHENE SEALER (FOOT STAMPING) -16 01.jpg"
import img36 from "../../images/products/General Hardware/Polythene Sealer/POLYTHENE SEALER (FIBER) 01 01.jpg"
import img37 from "../../images/products/General Hardware/Polythene Sealer/POLYTHENE SEALER (STEEL HD) 01.jpg"

import img38 from "../../images/products/General Hardware/Pop Gun/(89) POP RIVET GUN (GS OPTIMUS)-10.5 01.jpg"
import img39 from "../../images/products/General Hardware/Pop Gun/(92) POP RIVET GUN (VEIBER)-10 (175065) 01.jpg"
import img40 from "../../images/products/General Hardware/Pop Gun/(2144) pop rivet gun (jinma)-10.5 (jm-1003).jpg"
import img41 from "../../images/products/General Hardware/Pop Gun/(3099) POP RIVET GUN (JINMA)-09.5 (JM-1001) 01.jpg"
import img42 from "../../images/products/General Hardware/Pop Gun/(6240) POP RIVET GUN-LONG TYPE 01.jpg"
import img43 from "../../images/products/General Hardware/Pop Gun/(6241) POP RIVET GUN-CROSS TYPE 01.jpg"

import img44 from "../../images/products/General Hardware/Putty/(2402) PLASTERING TROWEL VEIBER-11(280MM)TEETH 01.jpg"
import img45 from "../../images/products/General Hardware/Putty/(2403) PLASTERING TROWEL VEIBER-11(280MM) 01.jpg"
import img46 from "../../images/products/General Hardware/Putty/PUTTY KNIFE VEIBER 01.jpg"
import img47 from "../../images/products/General Hardware/Putty/SCRAPER VEIBER (8163 01.jpg"

import img49 from "../../images/products/General Hardware/Stainless Steel/(1467) SS WIRE ROPE LOCK - 6MM 01.jpg"
import img50 from "../../images/products/General Hardware/Stainless Steel/(2159) THREAD LOCKER-GLUE (YST-271) RED 01.jpg"
import img51 from "../../images/products/General Hardware/Stainless Steel/(2952) GI Anchor bolt 10mm.jpg"
import img52 from "../../images/products/General Hardware/Stainless Steel/(6772) Gell-ss Scale Remover.jpg"
import img53 from "../../images/products/General Hardware/Stainless Steel/SS (202) THREAD BAR 2METER 02.jpg"
import img54 from "../../images/products/General Hardware/Stainless Steel/SS ANCHOR BOLT (202)(304) 01.jpg"
import img55 from "../../images/products/General Hardware/Stainless Steel/SS BENDER CLIP  (WIRE ROPE) 01.jpg"
import img56 from "../../images/products/General Hardware/Stainless Steel/SS BOLT AND NUT 01.jpg"
import img57 from "../../images/products/General Hardware/Stainless Steel/SS BULLDOG CLIP 01.jpg"
import img58 from "../../images/products/General Hardware/Stainless Steel/SS CARRIAGE BOLT 01.jpg"
import img59 from "../../images/products/General Hardware/Stainless Steel/SS CHAIN (304) 01.jpg"
import img60 from "../../images/products/General Hardware/Stainless Steel/SS CUP NUT (202)(304) 01.jpg"
import img61 from "../../images/products/General Hardware/Stainless Steel/Ss Cup Squrecarriage Bolt&c nut.jpg"
import img62 from "../../images/products/General Hardware/Stainless Steel/SS CUPBOARD HANDLE -  (BOX TYPE) 01.jpg"
import img63 from "../../images/products/General Hardware/Stainless Steel/SS CUPBOARD HANDLE -  (ROUND TYPE) 01.jpg"
import img64 from "../../images/products/General Hardware/Stainless Steel/SS FORKER TERMINAL (304) 01.jpg"
import img65 from "../../images/products/General Hardware/Stainless Steel/SS HACKER - (WIRE ROPE) 01.jpg"
import img66 from "../../images/products/General Hardware/Stainless Steel/SS HALF ROUND BALL Gold 01.jpg"
import img67 from "../../images/products/General Hardware/Stainless Steel/SS HALF ROUND BALL silver 01.jpg"
import img68 from "../../images/products/General Hardware/Stainless Steel/SS HOOK TYPE ANCHOR  BOLT (304) 01.jpg"
import img69 from "../../images/products/General Hardware/Stainless Steel/SS HOSE CLIP (HD) 01.jpg"
import img70 from "../../images/products/General Hardware/Stainless Steel/SS NUT ONLY 01.jpg"
import img71 from "../../images/products/General Hardware/Stainless Steel/SS THREAD TERMINAL 316 01.jpg"
import img72 from "../../images/products/General Hardware/Stainless Steel/SS TURN BUCKLE (304) 01.jpg"
import img73 from "../../images/products/General Hardware/Stainless Steel/SS WASHER 01.jpg"
import img74 from "../../images/products/General Hardware/Stainless Steel/SS WIRE HOSE CLIP (304) 01.jpg"
import img75 from "../../images/products/General Hardware/Stainless Steel/SS WIRE ROPE CABLE 01.jpg"
import img76 from "../../images/products/General Hardware/Stainless Steel/SS304 BOLT & NUT 01.jpg"

import img77 from "../../images/products/General Hardware/Stapler Gun/(2272) STAPLER GUN MANUAL-GUN TACKER4-14MM SHLI 01.jpg"
import img78 from "../../images/products/General Hardware/Stapler Gun/(2625) STAPLER GUN MANUAL-GUN TACKER4-8MM LEIYU 01.jpg"
import img79 from "../../images/products/General Hardware/Stapler Gun/(2938) STAPLER GUN MANUAL-FRAM TACKER (HM515) 01.jpg"
import img80 from "../../images/products/General Hardware/Stapler Gun/(2939) STAPLER PIN MANUAL-HM515 (10000PCS) FRAM 01.jpg"
import img81 from "../../images/products/General Hardware/Stapler Gun/(3676) STAPLER GUN MANUAL-GUN TACKER S-23 FIBER 01.jpg"
import img82 from "../../images/products/General Hardware/Stapler Gun/(6456) STAPLER GUN MANUAL-GUN TACKER (B-23) 01.jpg"
import img83 from "../../images/products/General Hardware/Stapler Gun/STAPLER PIN MANUAL 01.jpg"

import img84 from "../../images/products/General Hardware/Tape/(1555) double tape-2side glue (24mmx5m).jpg"
import img85 from "../../images/products/General Hardware/Tape/GLUE TAPE 01.jpg"

import img86 from "../../images/products/General Hardware/TROWEL/BRICKWORK TROWEL 01.jpg"

import img87 from "../../images/products/General Hardware/Vernier Caliper/(132) VERNIER CALIPER - 06 BLACK BOX 01.jpg"
import img88 from "../../images/products/General Hardware/Vernier Caliper/(223) VERNIER CALIPER - 08 BLACK BOX 01.jpg"
import img89 from "../../images/products/General Hardware/Vernier Caliper/(1884) VERNIER CALIPER - 06 MITUTOYO (HD) 01.jpg"
import img90 from "../../images/products/General Hardware/Vernier Caliper/(2041) VERNIER CALIPER - 6 ANALOG (WATCH TYPE) 01.jpg"
import img91 from "../../images/products/General Hardware/Vernier Caliper/(2042) Vernier Caliper - 06 Inside.jpg"
import img92 from "../../images/products/General Hardware/Vernier Caliper/(2043) Vernier Caliper - 06 Outside.jpg"
import img93 from "../../images/products/General Hardware/Vernier Caliper/(6109) VERNIER CALIPER - 06 DIGITAL 01.jpg"
import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"





const compressor = [
  {
    img:img1,
    name:"Die Grinder Accessories set - 105 set"
  },
  {
    img:img2,
    name:"paint zoom spray machine - portable"
  },
  {
    img:img3,
    name:"ELECTRIC SUCKER SOLDER (BOUTH)"
  },
  {
    img:img4,
    name:"ELECTRIC DIE GRINDER - PVC BOX"
  },
  {
    img:img5,
    name:"Die Grinder Accessories Set"
  },{
    img:img6,
    name:"ELECTRIC BOUTH"
  },
  {
    img:img7,
    name:"ELECTRIC BOUTH"
  },
  {
    img:img8,
    name:"GI ANCHOR BOLT 12X SLIVE 50MM BOLT 120M"
  },{
    img:img9,
    name:"GI ANCHOR BOLT"
  },
  {
    img:img10,
    name:"GI CARRIAGE BOLT"
  },
  {
    img:img11,
    name:"GLASS SUCTION VACUUM CUP THREE-JAR"
  },
{
    img:img12,
    name:"GLASS SUCTION CUP SINGLE 8 HD"
  },
{
    img:img13,
    name:"GLASS SUCTION VACUUM CUP Single HD"
  },{
    img:img14,
    name:"GLASS SUCTION VACUUM CUP DOUBLE HD"
  },
{
    img:img15,
    name:"GLASS CUTTER TOOL (OIL FEED WHEEL)"
  },
  {
    img:img16,
    name:"GLUE GUN - 100W (GG-8)"
  },
  {
    img:img17,
    name:"Vieber Ratchet Plastic Pipe cutter"
  },
  {
    img:img18,
    name:"SILICONE GUN FRAME"
  },
  {
    img:img19,
    name:"Silicone Gun Frame-blue"
  },
  {
    img:img20,
    name:"Silicone Gun Frame-orange"
  },{
    img:img21,
    name:"GLUE GUM - TUBE ONLY"
  },
  {
    img:img22,
    name:"GLUE GUN - (MODEL-2)"
  },
  {
    img:img23,
    name:"GLUE GUN - no1"
  },

{
    img:img24,
    name:"BOKE STEEL TAPE (PROFESSIONAL)"
  },
  {
    img:img25,
    name:"FIBER MEASURING TAPE PVC"
  },
  {
    img:img26,
    name:"finder pro steal tape"
  },
  {
    img:img27,
    name:"FINDER STEAL TAPE"
  },{
    img:img28,
    name:"MEASURING TAPECLOTH MEASURING TAPE  (YUEBAO)"
  },
  {
    img:img29,
    name:"MEASURING TAPEFINDER STEAL TAPE"
  },
  {
    img:img30,
    name:"STANLEY STEAL TAPE"
  },
 {
    img:img31,
    name:"PAINT ROLLER BRUSH WITH HANDLE (VEIBER)"
  },
{
    img:img32,
    name:"PAPER CUTTER KNIFE (FIBER)"
  },
{
    img:img33,
    name:"PAPER CUTTER-BLADE (10PCS BOX)"
  },
{
    img:img34,
    name:"PAPER CUTTER KNIFE SRUNV (SR-029) HAVY"
  },

{
    img:img35,
    name:"POLYTHENE SEALER (FOOT STAMPING)"
  },
{
    img:img36,
    name:"POLYTHENE SEALER (FIBER)"
  },
{
    img:img37,
    name:"POLYTHENE SEALER (STEEL HD)"
  },
  {
    img:img38,
    name:"POP RIVET GUN (GS OPTIMUS)-10.5"
  },
{
    img:img39,
    name:"POP RIVET GUN (VEIBER)-10"
  },
{
    img:img40,
    name:"pop rivet gun (jinma)-10.5"
  },
{
    img:img41,
    name:"POP RIVET GUN (JINMA)-09.5"
  },
{
    img:img42,
    name:"POP RIVET GUN-LONG TYPE"
  },
{
    img:img43,
    name:"POP RIVET GUN-CROSS TYPE"
  },
  {
    img:img44,
    name:"PLASTERING TROWEL VEIBER-11(280MM)TEETH"
  },
{
    img:img45,
    name:"PLASTERING TROWEL VEIBER-11(280MM)"
  },
{
    img:img46,
    name:"PUTTY KNIFE VEIBER"
  },
{
    img:img47,
    name:"SCRAPER VEIBER"
  },
{
    img:img49,
    name:"SS WIRE ROPE LOCK - 6MM"
  },
{
    img:img50,
    name:"THREAD LOCKER-GLUE (YST-271) RED"
  },

{
    img:img51,
    name:"GI Anchor bolt 10mm"
  },
{
    img:img52,
    name:"Gell-ss Scale Remover"
  },
{
    img:img53,
    name:"THREAD BAR 2METER"
  },
{
    img:img54,
    name:"SS ANCHOR BOLT (202)(304)"
  },

{
    img:img55,
    name:"SS BENDER CLIP  (WIRE ROPE)"
  },
{
    img:img56,
    name:"SS BOLT AND NUT"
  },
{
    img:img57,
    name:"SS BULLDOG CLIP"
  },
{
    img:img58,
    name:"SS CARRIAGE BOLT"
  },
{
    img:img59,
    name:"SS CHAIN (304)"
  },
{
    img:img60,
    name:"SS CUP NUT (202)(304)"
  },
{
    img:img61,
    name:"Ss Cup Squrecarriage Bolt&c nut"
  },
{
    img:img62,
    name:"SS CUPBOARD HANDLE -  (BOX TYPE)"
  },
{
    img:img63,
    name:"SS CUPBOARD HANDLE -  (ROUND TYPE)"
  },
{
    img:img64,
    name:"SS FORKER TERMINAL (304)"
  },

{
    img:img65,
    name:"SS HACKER - (WIRE ROPE)"
  },
{
    img:img66,
    name:"SS HALF ROUND BALL Gold"
  },
{
    img:img67,
    name:"SS HALF ROUND BALL silver"
  },
{
    img:img68,
    name:"SS HOOK TYPE ANCHOR  BOLT (304)"
  },

{
    img:img69,
    name:"SS HOSE CLIP (HD)"
  },
{
    img:img70,
    name:"SS NUT ONLY"
  },
{
    img:img71,
    name:"SS THREAD TERMINAL 316"
  },
{
    img:img72,
    name:"SS TURN BUCKLE (304)"
  },

{
    img:img73,
    name:"SS WASHER"
  },
{
    img:img74,
    name:"SS WIRE HOSE CLIP (304)"
  },
{
    img:img75,
    name:"SS WIRE ROPE CABLE"
  },
{
    img:img76,
    name:"SS304 BOLT & NUT"
  },
  {
    img:img77,
    name:" STAPLER GUN MANUAL-GUN TACKER4-14MM SHLI"
  },
{
    img:img78,
    name:"STAPLER GUN MANUAL-GUN TACKER4-8MM LEIYU"
  },
{
    img:img79,
    name:"STAPLER GUN MANUAL-FRAM TACKER (HM515)"
  },

{
    img:img80,
    name:"STAPLER PIN MANUAL-HM515 (10000PCS) FRAM"
  },
{
    img:img81,
    name:"STAPLER GUN MANUAL-GUN TACKER S-23 FIBER"
  },
{
    img:img82,
    name:"STAPLER GUN MANUAL-GUN TACKER (B-23)"
  },
{
    img:img83,
    name:"STAPLER PIN MANUAL"
  },
  {
    img:img84,
    name:"double tape-2side glue (24mmx5m)"
  },
{
    img:img85,
    name:"GLUE TAPE 01GLUE TAPE"
  },
  {
    img:img86,
    name:"BRICKWORK TROWEL"
  },
{
    img:img87,
    name:"VERNIER CALIPER - 06 BLACK BOX"
  },

{
    img:img88,
    name:"VERNIER CALIPER - 08 BLACK BOX"
  },
{
    img:img89,
    name:"VERNIER CALIPER - 06 MITUTOYO (HD)"
  },
{
    img:img90,
    name:"VERNIER CALIPER - 6 ANALOG (WATCH TYPE)"
  },
{
    img:img91,
    name:"Vernier Caliper - 06 Inside"
  },
{
    img:img92,
    name:"Vernier Caliper - 06 Outside"
  },
{
    img:img93,
    name:"VERNIER CALIPER - 06 DIGITAL"
  },


]

function NewProducts() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default NewProducts
