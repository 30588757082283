import React from "react"
import Slider from "react-slick"
import $ from 'jquery'
// importing images
import img1 from "../../images/products/Welding/Regulator/(217) YAMATO REGULATOR-ACETYLENE 01.jpg"
import img2 from "../../images/products/Welding/Regulator/(218) PRESCOTT REGULATOR-ARGON (PAR-609) 01.jpg"
import img3 from "../../images/products/Welding/Regulator/(268) YAMATO REGULATOR-VALVE PIN (REPAIR KIT) 01.jpg"
import img4 from "../../images/products/Welding/Regulator/(980) PRESCOTT REGULATOR-CO-2 GAS (PAR-238) 01.jpg"
import img5 from "../../images/products/Welding/Regulator/(981) PRESCOTT REGULATOR-HEAT CO-2 GAS(PCO138) 01.jpg"
import img6 from "../../images/products/Welding/Regulator/(1051) YAMATO REGULATOR-OXYGEN 01.jpg"
import img7 from "../../images/products/Welding/Regulator/(1694) MIUREX REGULATOR-ACETYLENE 01.jpg"
import img8 from "../../images/products/Welding/Regulator/(1873)Miurex Regulator-oxygen.jpg"
import img9 from "../../images/products/Welding/Regulator/(3112) GASCO REGULATOR-OXYGEN (GOX-2-L) 01.jpg"
import img10 from "../../images/products/Welding/Regulator/(5045) PRESCOTT REGULATOR-OXYGEN (POX-128) 01.jpg"
import img11 from "../../images/products/Welding/Regulator/(5186) PRESCOTT REGULATOR-ACETYLENE (PAX-228) 01.jpg"
import img12 from "../../images/products/Welding/Regulator/(6773) PRESCOTT REGULATOR-ARGON (PAR-238) 01.jpg"

import img13 from "../../images/products/Welding/Welding/(278) GASBAR PAINT MARKER MIXED(WBR) 3MM 12PCS 01.jpg"
import img14 from "../../images/products/Welding/Welding/(294) PLASTIC WELDING GUN - BLACK TOOL & BOX 01.jpg"
import img15 from "../../images/products/Welding/Welding/(295) PLASTIC WELDING GUN - H50 01.jpg"
import img16 from "../../images/products/Welding/Welding/(847) W Head Seal copy 01.jpg"
import img17 from "../../images/products/Welding/Welding/(1212) WELDING HEAD SHIELD (BLACK) 01.jpg"
import img18 from "../../images/products/Welding/Welding/(2181) TIG ROD 308L - 1.6MM (5KG) 01.jpg"
import img19 from "../../images/products/Welding/Welding/(2299) WELDING HEAD SHIELD-AUTO DARK (BLUE BOX) 01.jpg"
import img20 from "../../images/products/Welding/Welding/(2483) GASBAR ELECTRODES-24MMX175MM ALUMI 10PCS 01.jpg"
import img21 from "../../images/products/Welding/Welding/(2622) WELDING HAND SHIELD (BLUE) NORMAL 01.jpg"
import img22 from "../../images/products/Welding/Welding/FLASH B-ARRESTOR-REGULATOR ACETYLEN 01.jpg"
import img23 from "../../images/products/Welding/Welding/FLASH B-ARRESTOR-REGULATOR OXYGEN 01.jpg"
import img24 from "../../images/products/Welding/Welding/FLASH B-ARRESTOR-TORCH ACETYLENE 01.jpg"
import img25 from "../../images/products/Welding/Welding/FLASH B-ARRESTOR-TORCH OXYGEN 01.jpg"
import img26 from "../../images/products/Welding/Welding/GASBAR ELECTRODES 01.jpg"
import img27 from "../../images/products/Welding/Welding/WELDING CABLE LUG 01.jpg"
import img28 from "../../images/products/Welding/Welding/WELDING EARTH CLAMP 01.jpg"

import img29 from "../../images/products/Welding/Welding Cutter/(431) WELDING CUTTER-MIUREX 250MM 01.jpg"
import img30 from "../../images/products/Welding/Welding Cutter/(438) WELDING CUTTER NOZZLE-CLEANER SET 01.jpg"
import img31 from "../../images/products/Welding/Welding Cutter/(1558) WELDING CUTTER (MIUREX)-HEAD NUT 01.jpg"
import img32 from "../../images/products/Welding/Welding Cutter/(1901) WELDING CUTTER-PRESCOTT PG-250MM 01.jpg"
import img33 from "../../images/products/Welding/Welding Cutter/(6236) WELDING CUTTER (MIUREX)-NOB (RED&BLUE) 01.jpg"
import img34 from "../../images/products/Welding/Welding Cutter/MIUREX CUTTER WELDING NOZZLE- (ANME) 01.jpg"
import img35 from "../../images/products/Welding/Welding Cutter/MIUREX CUTTER WELDING NOZZLE-(PNME) 01.jpg"

import img36 from "../../images/products/Welding/Welding Glass/(95) WELDING GLASS - BLUE (NORMAL) 01.jpg"
import img37 from "../../images/products/Welding/Welding Glass/(99) WELDING GLASS - GREEN (NORMAL) 01.jpg"
import img38 from "../../images/products/Welding/Welding Glass/(1211) WELDING GLASS - CLEAR (NO-1) 01.jpg"
import img39 from "../../images/products/Welding/Welding Glass/(1632) WELDING GLASS - BLACK (NO-1) 01.jpg"
import img40 from "../../images/products/Welding/Welding Glass/(6265) WELDING GLASS - CLEAR (NORMAL) 01.jpg"
import img41 from "../../images/products/Welding/Welding Glass/(6548) WELDING GLASS - BLACK (NORMAL) 01.jpg"

import img42 from "../../images/products/Welding/Welding Goggle/(2523) WELDING GOGGLES-AUTO DARK (TX-012) 01.jpg"
import img43 from "../../images/products/Welding/Welding Goggle/(6550) WELDING GOGGLES - BLACK 01.jpg"
import img44 from "../../images/products/Welding/Welding Goggle/U(2524) Welding Goggles-auto Dark (Tx-009) 01.jpg"

import img45 from "../../images/products/Welding/Welding Holder/(261) Welding Holder.jpg"
import img46 from "../../images/products/Welding/Welding Holder/(639) INVERTER WELDING PLANT HOLDER (200AMP) 01.jpg"
import img47 from "../../images/products/Welding/Welding Holder/(1042)(263) Welding Holder.jpg"
import img48 from "../../images/products/Welding/Welding Holder/MAGNETIC WELDING HOLDER 01.jpg"
import img49 from "../../images/products/Welding/Welding Holder/WELDING HOLDER - AMERICAN 01.jpg"
import img50 from "../../images/products/Welding/Welding Holder/WELDING HOLDER - PRESCOTT 01.jpg"

import img51 from "../../images/products/Welding/Welding Plant/(939) INTIMAX MIG PLANT (MIGMMA-225).jpg"
import img52 from "../../images/products/Welding/Welding Plant/(2710) INTIMAX INVERTER PLANT MIGMMA-305 81530.jpg"
import img53 from "../../images/products/Welding/Welding Plant/(2711) INTIMAX INVERTER PLANT TIGMMA-250 81625.jpg"
import img54 from "../../images/products/Welding/Welding Plant/(2712) INTIMAX INVERTER PLANT MIGMMA415S 15KG.jpg"
import img55 from "../../images/products/Welding/Welding Plant/(2713) INTIMAX INVERTER PLANT MIGMMA-350S.jpg"
import img56 from "../../images/products/Welding/Welding Plant/(2714) INTIMAX INVERTER PLANT CUT-80 (081180).jpg"
import img57 from "../../images/products/Welding/Welding Plant/(2716) INTIMAX INVERTER PLANT BEST-310 (083531) 0.jpg"
import img58 from "../../images/products/Welding/Welding Plant/(3076) INTIMAX INVERTER PLANT MMA-315 S5-087031.jpg"
import img59 from "../../images/products/Welding/Welding Plant/(3077) INTIMAX INVERTER PLANT TIGMMA-300 81630.jpg"
import img60 from "../../images/products/Welding/Welding Plant/(3172) INTIMAX INVERTER PLANT MMA-315S (MOS).jpg"

import img61 from "../../images/products/Welding/Welding Torch/(846) PRESCOTT WELDING TORCH (PWD-28) BRASS 01.jpg"
import img62 from "../../images/products/Welding/Welding Torch/(1661) TIG WELDING TORCH - SPARE SETS (9PCS) 01.jpg"
import img63 from "../../images/products/Welding/Welding Torch/(1874) PRESCOTT WELDING TORCH (PWD-18) 01.jpg"
import img64 from "../../images/products/Welding/Welding Torch/(1961) PRESCOTT WELDING HEATING TORCH PKJ-500 01.jpg"
import img65 from "../../images/products/Welding/Welding Torch/(2747) PLASMA WELDING TORCH (CUT-80) 01.jpg"
import img66 from "../../images/products/Welding/Welding Torch/(2867) PLASMA TORCH NOZZLE SET AP-60 (3PCS) 01.jpg"
import img67 from "../../images/products/Welding/Welding Torch/(2868) PLASMA TORCH NOZZLE SET AP-80 (3PCS) 01.jpg"
import img68 from "../../images/products/Welding/Welding Torch/(3046) TIG TORCH BACK CUP - LONG (BLACK) 01.jpg"
import img69 from "../../images/products/Welding/Welding Torch/(3094) MIG TORCH (AK-24) - SWAN NECK 01.jpg"
import img70 from "../../images/products/Welding/Welding Torch/(3098) MIG TORCH (AK-15) - SWAN NECK 0 1.jpg"
import img71 from "../../images/products/Welding/Welding Torch/(3104) MIG TORCH (AK-24) - BENZIL LINER CABLE 01.jpg"
import img72 from "../../images/products/Welding/Welding Torch/(3105) WELDING TORCH CERAMIC NOZZLE - AG-60 01.jpg"
import img73 from "../../images/products/Welding/Welding Torch/(3106) WELDING TORCH CERAMIC NOZZLE - 40 01.jpg"
import img74 from "../../images/products/Welding/Welding Torch/(3107) TORCH AK-24 CERAMIC NOZZLE - GAS DEFUSER 01.jpg"
import img75 from "../../images/products/Welding/Welding Torch/(4089) SUPER WELDING HEATING TORCH (H.G.Q) 01.jpg"
import img76 from "../../images/products/Welding/Welding Torch/(5271) FINE WELDING TORCH (YH02-6 ) 01.jpg"
import img77 from "../../images/products/Welding/Welding Torch/FINE TORCH - WELDING TIPS LW  01.jpg"
import img78 from "../../images/products/Welding/Welding Torch/MIG TORCH (AK-15)-TIP NOZZLE 01.jpg"
import img79 from "../../images/products/Welding/Welding Torch/MIG TORCH (AK-24)-TIP NOZZLE 01.jpg"
import img80 from "../../images/products/Welding/Welding Torch/PRESCOTT TORCH NOZZLE 01.jpg"
import img81 from "../../images/products/Welding/Welding Torch/TIG WELDING TORCH 01.jpg"
import img82 from "../../images/products/Welding/Welding Torch/WELDING TORCH CERAMIC NOZZLE 01.jpg"
import img83 from "../../images/products/Welding/Welding Torch/WELDING TORCH MIG AK copy 01.jpg"
import img84 from "../../images/products/Welding/Welding Torch/WELDING TORCH MIG AK400-ROLLER 01.jpg"
import img85 from "../../images/products/Welding/Welding Torch/WELDING TORCH NRETURN- ACETYLENE 01.jpg"
import img86 from "../../images/products/Welding/Welding Torch/WELDING TORCH NRETURN- OXYGE 01.jpg"
import ContactUsModal from "../ContactUsModal"


const compressor = [
  {
    img:img1,
    name:"YAMATO REGULATOR-ACETYLENE"
  },
  {
    img:img2,
    name:"PRESCOTT REGULATOR-ARGON (PAR-609)"
  },{
    img:img3,
    name:"YAMATO REGULATOR-VALVE PIN (REPAIR KIT)"
  },
 {
    img:img4,
    name:"PRESCOTT REGULATOR-CO-2 GAS (PAR-238)"
  },
  {
    img:img5,
    name:"PRESCOTT REGULATOR-HEAT CO-2 GAS(PCO138)"
  },{
    img:img6,
    name:"YAMATO REGULATOR-OXYGEN"
  },
 {
    img:img7,
    name:"MIUREX REGULATOR-ACETYLENE"
  },
  {
    img:img8,
    name:"Miurex Regulator-oxygen"
  },{
    img:img9,
    name:"GASCO REGULATOR-OXYGEN (GOX-2-L)"
  },
 {
    img:img10,
    name:"PRESCOTT REGULATOR-OXYGEN (POX-128)"
  },
  {
    img:img11,
    name:"PRESCOTT REGULATOR-ACETYLENE (PAX-228)"
  },{
    img:img12,
    name:"PRESCOTT REGULATOR-ARGON (PAR-238)"
  },
  
  {
    img:img13,
    name:"GASBAR PAINT MARKER MIXED(WBR) 3MM 12PCS"
  },
  {
    img:img14,
    name:"PLASTIC WELDING GUN - BLACK TOOL & BOX"
  },{
    img:img15,
    name:"PLASTIC WELDING GUN - H50"
  },
 {
    img:img16,
    name:"W Head Seal"
  },
  {
    img:img17,
    name:"WELDING HEAD SHIELD (BLACK)"
  },{
    img:img18,
    name:"TIG ROD 308L - 1.6MM (5KG)"
  },
 {
    img:img19,
    name:"WELDING HEAD SHIELD-AUTO DARK (BLUE BOX)"
  },
  {
    img:img20,
    name:"GASBAR ELECTRODES-24MMX175MM ALUMI 10PCS"
  },{
    img:img21,
    name:"WELDING HAND SHIELD (BLUE) NORMAL"
  },
 {
    img:img22,
    name:"FLASH B-ARRESTOR-REGULATOR ACETYLEN"
  },
  {
    img:img23,
    name:"FLASH B-ARRESTOR-REGULATOR OXYGEN"
  },{
    img:img24,
    name:"FLASH B-ARRESTOR-TORCH ACETYLENE"
  },{
    img:img25,
    name:"FLASH B-ARRESTOR-TORCH OXYGEN"
  },
 {
    img:img26,
    name:"GASBAR ELECTRODES"
  },
  {
    img:img27,
    name:"WELDING CABLE LUG"
  },{
    img:img28,
    name:"WELDING EARTH CLAMP"
  },
  {
    img:img29,
    name:"WELDING CUTTER-MIUREX 250MM"
  },
  {
    img:img30,
    name:"WELDING CUTTER NOZZLE-CLEANER SET"
  },{
    img:img31,
    name:"WELDING CUTTER (MIUREX)-HEAD NUT"
  },
 {
    img:img32,
    name:"WELDING CUTTER-PRESCOTT PG-250MM"
  },
  {
    img:img33,
    name:"WELDING CUTTER (MIUREX)-NOB (RED&BLUE)"
  },{
    img:img34,
    name:"CUTTER WELDING NOZZLE- (ANME)"
  },
 {
    img:img35,
    name:"CUTTER WELDING NOZZLE-(PNME)"
  },

{
    img:img36,
    name:"WELDING GLASS - BLUE (NORMAL)"
  },{
    img:img37,
    name:"WELDING GLASS - GREEN (NORMAL)"
  },
 {
    img:img38,
    name:"WELDING GLASS - CLEAR (NO-1)"
  },
  {
    img:img39,
    name:"WELDING GLASS - BLACK (NO-1)"
  },{
    img:img40,
    name:"WELDING GLASS - CLEAR (NORMAL)"
  },
 {
    img:img41,
    name:"WELDING GLASS - BLACK (NORMAL)"
  },
{
    img:img42,
    name:"WELDING GOGGLES-AUTO DARK (TX-012)"
  },{
    img:img43,
    name:"WELDING GOGGLES - BLACK"
  },
 {
    img:img44,
    name:"Welding Goggles-auto Dark (Tx-009)"
  },

{
    img:img45,
    name:"Welding Holder"
  },{
    img:img46,
    name:"INVERTER WELDING PLANT HOLDER (200AMP)"
  },
 {
    img:img47,
    name:"Welding Holder"
  },
{
    img:img48,
    name:"MAGNETIC WELDING HOLDER"
  },{
    img:img49,
    name:"WELDING HOLDER - AMERICAN"
  },
 {
    img:img50,
    name:"WELDING HOLDER - PRESCOTT"
  },
  {
    img:img51,
    name:"INTIMAX MIG PLANT (MIGMMA-225)"
  },
{
    img:img52,
    name:"INTIMAX INVERTER PLANT MIGMMA-305"
  },
{
    img:img53,
    name:"INTIMAX INVERTER PLANT TIGMMA-250"
  },
{
    img:img54,
    name:"INTIMAX INVERTER PLANT MIGMMA415S 15KG"
  },
{
    img:img55,
    name:"INTIMAX INVERTER PLANT MIGMMA-350S"
  },
{
    img:img56,
    name:"INTIMAX INVERTER PLANT CUT-80"
  },
{
    img:img57,
    name:"INTIMAX INVERTER PLANT BEST-310 (083531)"
  },
{
    img:img58,
    name:"INTIMAX INVERTER PLANT MMA-315 S5-087031"
  },			
{
    img:img59,
    name:"INTIMAX INVERTER PLANT TIGMMA-300"
  },
{
    img:img60,
    name:"INTIMAX INVERTER PLANT MMA-315S (MOS)"
  },
{
    img:img61,
    name:"PRESCOTT WELDING TORCH (PWD-28) BRASS"
  },
{
    img:img62,
    name:"TIG WELDING TORCH - SPARE SETS (9PCS)"
  },
{
    img:img63,
    name:"PRESCOTT WELDING TORCH (PWD-18)"
  },
{
    img:img64,
    name:"PRESCOTT WELDING HEATING TORCH PKJ-500"
  },
{
    img:img65,
    name:"PLASMA WELDING TORCH (CUT-80)"
  },
{
    img:img66,
    name:"PLASMA TORCH NOZZLE SET AP-60 (3PCS)"
  },
{
    img:img67,
    name:"PLASMA TORCH NOZZLE SET AP-80 (3PCS)"
  },
{
    img:img68,
    name:"TIG TORCH BACK CUP - LONG (BLACK)"
  },			
{
    img:img69,
    name:"MIG TORCH (AK-24) - SWAN NECK"
  },
{
    img:img70,
    name:"MIG TORCH (AK-15) - SWAN NECK"
  },
{
    img:img71,
    name:"MIG TORCH (AK-24) - BENZIL LINER CABLE"
  },
{
    img:img72,
    name:"WELDING TORCH CERAMIC NOZZLE - AG-60"
  },
{
    img:img73,
    name:"WELDING TORCH CERAMIC NOZZLE"
  },
{
    img:img74,
    name:"TORCH AK-24 CERAMIC NOZZLE - GAS DEFUSER"
  },
{
    img:img75,
    name:"SUPER WELDING HEATING TORCH (H.G.Q)"
  },
{
    img:img76,
    name:"FINE WELDING TORCH (YH02-6 )"
  },
{
    img:img77,
    name:"FINE TORCH - WELDING TIPS LW"
  },
{
    img:img78,
    name:"MIG TORCH (AK-15)-TIP NOZZLE"
  },			
{
    img:img79,
    name:"MIG TORCH (AK-24)-TIP NOZZLE"
  },
{
    img:img80,
    name:"PRESCOTT TORCH NOZZLE"
  },
{
    img:img81,
    name:"TIG WELDING TORCH"
  },
{
    img:img82,
    name:"WELDING TORCH CERAMIC NOZZLE"
  },
{
    img:img83,
    name:"WELDING TORCH MIG AK"
  },
{
    img:img84,
    name:"WELDING TORCH MIG AK400-ROLLER"
  },
{
    img:img85,
    name:"WELDING TORCH NRETURN- ACETYLENE"
  },
{
    img:img86,
    name:"WELDING TORCH NRETURN- OXYGE"
  },
]

function PowerTools() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                    {/*</button>*/}
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default PowerTools
