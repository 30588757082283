import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images

import img1 from "../../images/products/Safety Items/(94) SAFETY HAND GLOVE-VEIBER (24PCS) COLOURS 01.jpg"
import img2 from "../../images/products/Safety Items/(869) Safety Hand Glove (Yellow).jpg"
import img3 from "../../images/products/Safety Items/(912) SAFETY COAT (XL) 01.jpg"
import img4 from "../../images/products/Safety Items/(1279) SAFETY HAND GLOVE-VEIBER (12PCS BOX) 01.jpg"
import img5 from "../../images/products/Safety Items/(1424) SAFETY EAR PLUG (NORMAL) 01.jpg"
import img6 from "../../images/products/Safety Items/(1425) WELDING GLOVE (BLUE) HARD WORK 14 01.jpg"
import img7 from "../../images/products/Safety Items/(1502) Safety Hand Glove-blue (Rubber).jpeg"
import img8 from "../../images/products/Safety Items/(1503) Safety Hand Glove-black (Rubber).jpg"
import img9 from "../../images/products/Safety Items/(1633) SAFETY WARNING TAPE-RIBBON TIGHT 01.jpg"
import img10 from "../../images/products/Safety Items/(1634) SAFETY CORN LIGHT (RED) 01.jpg"
import img11 from "../../images/products/Safety Items/(1886) SAFETY BELT INDUSTRIAL (FULL KIT) 01.jpg"
import img12 from "../../images/products/Safety Items/(1955) SAFETY EAR PROTECTOR (E010) 01.jpg"
import img13 from "../../images/products/Safety Items/(2115) PICKUP TOOL LIGHT (MAGNATIC) 01.jpg"
import img14 from "../../images/products/Safety Items/(2155) SAFETY SEAL MASK (305+) INDUSTRIAL 01.jpg"
import img15 from "../../images/products/Safety Items/(2292) SAFETY PAINT MASK 3M (6800) 01.jpg"
import img16 from "../../images/products/Safety Items/(2295) SAFETY CAUTION BOARD (NO PARKING) YELLOW 01.jpg"
import img17 from "../../images/products/Safety Items/(2296) SAFETY CAUTION BOARD (WET FLOOR) YELLOW 01.jpg"
import img18 from "../../images/products/Safety Items/(2297) SAFETY DUST MASK (BLUE COLOUR) 01.jpg"
import img19 from "../../images/products/Safety Items/(2305) SAFETY HELMET BLUE 01.jpg"
import img20 from "../../images/products/Safety Items/(2379) HAND LIGHT LED (RE-CHARGEABLE) AISHITE 01.jpg"
import img21 from "../../images/products/Safety Items/(2525) SAFETY LIGHT-TRAFFIC CONE (YELLOW) 01.jpg"
import img22 from "../../images/products/Safety Items/(2526) SAFETY HAND REVOLVING LIGHT YT-966 01.jpg"
import img23 from "../../images/products/Safety Items/(2666) BASE STICK (BLUE & NICAL) 01.jpg"
import img24 from "../../images/products/Safety Items/(2675) TOOL HOLDER BELT (SDY-90522) 01.jpg"
import img25 from "../../images/products/Safety Items/(2953) SAFETY JACKET (150G) HAVY 01.jpg"
import img26 from "../../images/products/Safety Items/(3039) SAFETY BELT INDUSTRIAL  01.jpg"
import img27 from "../../images/products/Safety Items/(5185) WELDING GLOVE (RED) HARD WORK 14 01.jpg"
import img28 from "../../images/products/Safety Items/(6030) SAFETY HELMET YELLOW 01.jpg"
import img29 from "../../images/products/Safety Items/(6031) SAFETY HELMET WHITE 01.jpg"
import img30 from "../../images/products/Safety Items/(6142) SAFETY JACKET ORANGE 01.jpg"
import img31 from "../../images/products/Safety Items/(6143) SAFETY JACKET GREEN 01.jpg"
import img32 from "../../images/products/Safety Items/SAFETY OVERALL KIT 01.jpg"
import img33 from "../../images/products/Safety Items/SAFETY PLASTIC CHAIN 01.jpg"
import img34 from "../../images/products/Safety Items/SAFETY SHOE ROCKLANDER 01.jpg"
import img35 from "../../images/products/Safety Items/SAFETY TRAFFIC CONE 01.jpg"
import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"

const blocks = [
  {
    img:img1,
    name:"SAFETY HAND GLOVE-VEIBER (24PCS) COLOURS"
  },
  {
    img:img2,
    name:"Safety Hand Glove (Yellow)"
  },
  {
    img:img3,
    name:"SAFETY COAT (XL)"
  },
  {
    img:img4,
    name:"SAFETY HAND GLOVE-VEIBER (12PCS BOX)"
  },
  {
    img:img5,
    name:"SAFETY EAR PLUG (NORMAL)"
  },
  {
    img:img6,
    name:"WELDING GLOVE (BLUE) HARD WORK"
  },
  {
    img:img7,
    name:"Safety Hand Glove-blue (Rubber)"
  },
{
    img:img8,
    name:"Safety Hand Glove-black (Rubber)"
  },			
{
    img:img9,
    name:"SAFETY WARNING TAPE-RIBBON TIGHT"
  },
{
    img:img10,
    name:"SAFETY CORN LIGHT (RED)"
  },
{
    img:img11,
    name:"SAFETY BELT INDUSTRIAL (FULL KIT)"
  },
{
    img:img12,
    name:"SAFETY EAR PROTECTOR (E010)"
  },
{
    img:img13,
    name:"PICKUP TOOL LIGHT (MAGNATIC)"
  },
{
    img:img14,
    name:"SAFETY SEAL MASK (305+) INDUSTRIAL"
  },
{
    img:img15,
    name:"SAFETY PAINT MASK 3M (6800)"
  },
{
    img:img16,
    name:"SAFETY CAUTION BOARD (NO PARKING) YELLOW"
  },
{
    img:img17,
    name:"SAFETY CAUTION BOARD (WET FLOOR) YELLOW"
  },
{
    img:img18,
    name:"SAFETY DUST MASK (BLUE COLOUR)"
  },
{
    img:img19,
    name:"SAFETY HELMET BLUE"
  },
{
    img:img20,
    name:"HAND LIGHT LED (RE-CHARGEABLE) AISHITE"
  },
{
    img:img21,
    name:"SAFETY LIGHT-TRAFFIC CONE (YELLOW)"
  },
{
    img:img22,
    name:"SAFETY HAND REVOLVING LIGHT YT-966"
  },
{
    img:img23,
    name:"BASE STICK (BLUE & NICAL)"
  },
{
    img:img24,
    name:"TOOL HOLDER BELT (SDY-90522)"
  },
{
    img:img25,
    name:"SAFETY JACKET (150G) HAVY"
  },
{
    img:img26,
    name:"SAFETY BELT INDUSTRIAL"
  },
{
    img:img27,
    name:"WELDING GLOVE (RED) HARD WORK"
  },
{
    img:img28,
    name:"SAFETY HELMET YELLOW"
  },
{
    img:img29,
    name:"SAFETY HELMET WHITE"
  },
{
    img:img30,
    name:"SAFETY JACKET ORANGE"
  },
  {
    img:img31,
    name:"SAFETY JACKET GREEN"
  },
  {
    img:img32,
    name:"SAFETY OVERALL KIT"
  },
  {
    img:img33,
    name:"SAFETY PLASTIC CHAIN"
  },
  {
    img:img34,
    name:"SAFETY SHOE ROCKLANDER"
  },
  {
    img:img35,
    name:"SAFETY TRAFFIC CONE"
  },
]

function ChainBlocks() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {blocks.map((name, index) => (
              <a  className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={blocks[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {blocks[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default ChainBlocks
