import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images
import img1 from "/src/images/products/Lath Tools, Pad & Disc Items/Die Handle/(1962) THREADING DIE HANDLE 34 (20 X 7) 01.jpg"
import img2 from "/src/images/products/Lath Tools, Pad & Disc Items/Die Handle/(1964) THREADING DIE HANDLE 1 (25 X 9) 01.jpg"
import img3 from "/src/images/products/Lath Tools, Pad & Disc Items/Die Handle/(1965) THREADING DIE HANDLE 1 14 (30 X 11) 01.jpg"
import img4 from "/src/images/products/Lath Tools, Pad & Disc Items/Die Handle/(2096) THREADING DIE HANDLE 1 (INDIAN) 01.jpg"
//not working
import img5 from "/src/images/products/Lath Tools, Pad & Disc Items/Fiber/FIBER DISC 4 040 01.jpg"
import img6 from "/src/images/products/Lath Tools, Pad & Disc Items/Fiber/FIBER DISC 4.5 04001.jpg"

//other images
import img7 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(221) MOUNTAIN STONE - SHANK BITS (10PCS CARD) New 01.jpg"
import img8 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(234) CUP GRINDING WHEEL - 2 X 2 (MOUNTAIN) 01.jpg"
import img9 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(235) CUP GRINDING WHEEL - 4 X 2 (MOUNTAIN) 01.jpg"
import img10 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(238) GRINDING WHEEL NUT - SMALL 01.jpg"
import img11 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(239) GRINDING WHEEL NUT - LARGE 01.jpg"
import img12 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(802) GRINDING WHEEL - WHITE 8 X 14 FLAT 01.jpg"
import img13 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(803) GRINDING WHEEL - WHITE 8 X 12 FLAT 01.jpg"
import img14 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(1220) BRANCA GRINDING WHEEL - 7 X 14 01.jpg"
import img15 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(1830) BRANCA GRINDING WHEEL - 4 X 14 01.jpg"
// not working below one
import img16 from "../../images/products/Lath Tools, Pad & Disc Items/Grinding/(2050) BLACK GRINDING WHEEL - 4 X 46 01.jpg"
import img17 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2459) HAND GRINDER - GRINDING WHEEL 4 01.jpg"
import img18 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2840) CARBRIDE ROTARY FILE CX1425M-06 01.jpg"
import img19 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2843) CARBRIDE ROTARY FILE DX1412-M06 01.jpg"
import img20 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2844) CARBRIDE ROTARY FILE EX1016-M06 01.jpg"
import img21 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2848) CARBRIDE ROTARY FILE FX1225-M06 01.jpg"
import img22 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2851) CARBRIDE ROTARY FILE GX1225-M06 01.jpg"
import img23 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(2853) CARBRIDE ROTARY FILE LX1025-M06 01.jpg"
import img24 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(3750) FLAT GRINDING WHEEL - 5X12 (MOUNTAIN) 01.jpg"
import img25 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(3753) GRINDING WHEEL - 6 X 1 (GREEN GC) 01.jpg"
import img26 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(3754) GRINDING WHEEL - 8 X 1 (GREEN GC) 01.jpg"
import img27 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(4076) MOUNTAIN STONE - SHANK BITS (5PCS CARD) 01.jpg"
import img28 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/(6246) grinding wheel - 8 x 1 (smooth) 01.jpg"
import img29 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/CARBRIDE ROTARY FILE MX 01.jpg"
import img30 from "/src/images/products/Lath Tools, Pad & Disc Items/Grinding/GRINDING WHEEL - GREEN GC 4 01.jpg"
// next product
import img31 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(26) SDS PLUS DRILL BIT 14X240MM (NOSE) 01.jpg"
import img32 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(27) SDS PLUS DRILL BIT 14X240MM (FLAT) 01.jpg"
import img33 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(28) SDS PLUS DRILL BIT 260MM (BIG FLAT) 01.jpg"
import img34 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(29) SDS MAX DRILL BIT 18X450MM (FLAT) 01.jpg"
import img35 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(30) SDS MAX DRILL BIT 18X450MM (NOSE) 01.jpg"
import img36 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(33) IRWIN MATIONARY DRILL BIT 05MM 01.jpg"
import img37 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(36) IRWIN MATIONARY DRILL BIT 08MM 01.jpg"
import img38 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(37) IRWIN MATIONARY DRILL BIT 10MM 01.jpg"
import img39 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(201) F WOOD BIT SET - 6PCS (JBANDARI) 01.jpg"
import img40 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(279) DIAMOND DRILL BIT 35MM ORANGE (ZHIWEI) 01.jpg"
import img41 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(280) DIAMOND DRILL BIT 40MM ORANGE (ZHIWEI) 01.jpg"
import img42 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(281) DIAMOND DRILL BIT 45MM ORANGE (ZHIWEI) 01.jpg"
import img43 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(282) DIAMOND DRILL BIT 50MM ORANGE (ZHIWEI) 01.jpg"
import img44 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(918) GLASS DRILL BIT 14MM 01.jpg"
import img45 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(1689) SKOLE SDS DRILL BIT 14X250MM (N) NO-1 01.jpg"
import img46 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(1690) SDS Breaker Bit.jpg"
import img47 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2169) DIAMOND CORE BIT 2.5 (63MM) 01.jpg"
import img48 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2170) DIAMOND CORE BIT 3 (76MM) 01.jpg"
import img49 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2171) DIAMOND CORE BIT 3.5 (83MM) 01.jpg"
import img50 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2172) DIAMOND CORE BIT 4 (102MM) 01.jpg"
import img51 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2173) DIAMOND CORE BIT ADAPTER (230MM) 01.jpg"
import img52 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2180) GLASS & TILE DRILL BIT 14MM (FINE) 01.jpg"
import img53 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2234) GLASS DRILL BIT 32MM 01.jpg"
import img54 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2308) DIAMOND DRILL BIT 06MM ORANGE (ZHIWEI) 01.jpg"
import img55 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2309) DIAMOND DRILL BIT 08MM ORANGE (ZHIWEI) 01.jpg"
import img56 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2310) DIAMOND DRILL BIT 10MM ORANGE (ZHIWEI) 01.jpg"
import img57 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2311) DIAMOND DRILL BIT 12MM  ORANGE (ZHIWEI) 01.jpg"
import img58 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2312) DIAMOND DRILL BIT 14MM ORANGE (ZHIWEI) 01.jpg"
import img59 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2313) DIAMOND DRILL BIT 16MM  ORANGE (ZHIWEI) 01.jpg"
import img60 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2314) DIAMOND DRILL BIT 20MM ORANGE (ZHIWEI) 01.jpg"
import img61 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2315) DIAMOND DRILL BIT 22MM ORANGE (ZHIWEI) 01.jpg"
import img62 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2316) DIAMOND DRILL BIT 25MM ORANGE (ZHIWEI) 01.jpg"
import img63 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2317) DIAMOND DRILL BIT 28MM ORANGE (ZHIWEI) 01.jpg"
import img64 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2318) DIAMOND DRILL BIT 30MM ORANGE (ZHIWEI) 01.jpg"
import img65 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2319) DIAMOND DRILL BIT 32MM ORANGE (ZHIWEI) 01.jpg"
import img66 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2413) DIAMOND CORE BIT ADAPTER (400MM) 01.jpg"
import img67 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2419) DIAMOND CORE BIT 1 (25MM) 01.jpg"
import img68 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2420) DIAMOND CORE BIT 1.5 (38MM) 01.jpg"
import img69 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2582) WHITE PACK MAX DRILL BIT 30X800MM REEKE 01.jpg"
import img70 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2620) DIAMOND CORE BIT 6 (152MM) 01.jpg"
import img71 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2686) DRILL BIT ADAPTER MT3 01.jpg"
import img72 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2764) DIAMOND CORE BIT 5 (127MM) 01.jpg"
import img73 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2778) DIAMOND DRILL BIT 18MM  ORANGE (ZHIWEI) 01.jpg"
import img74 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(2951) DIAMOND CORE BIT ADAPTER (160MM) 01.jpg"
import img75 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3080) SKOLE SDS DRILL BIT 14X250MM (N) NORMAL 01.jpg"
import img76 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3114) CORE BIT ADAPTER (REDUCER) 01.jpg"
import img77 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3115) DIAMOND CORE BIT 4 (102MM) OXY HAVY 01.jpg"
import img78 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3683) SDS PLUS SDS DRILL BIT 14X450MM (FLAT) 01.jpg"
import img79 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3684) SDS PLUS SDS DRILL BIT 14X450MM (NOSE) 01.jpg"
import img80 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3986) IRWIN SDS DRILL BIT 07X110MM NO-1 QUALTY 01.jpg"
import img81 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(3996) IRWIN SDS DRILL BIT 14X160MM NO-1 QUALTY 01.jpg"
import img82 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6025) SDS HEX DRILL BIT 17MM X 280MM (FLAT) 01.jpg"
import img83 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6026) SDS HEX DRILL BIT 17MM X 280MM (NOSE) 01.jpg"
import img84 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6037) GLASS DRILL BIT 10MM 01.jpg"
import img85 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6038) GLASS DRILL BIT 12MM 01.jpg"
import img86 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6039) GLASS DRILL BIT 30MM 01.jpg"
import img87 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6055) GLASS DRILL BIT 06MM 01.jpg"
import img88 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6056) GLASS DRILL BIT 07MM 01.jpg"
import img89 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6058) GLASS DRILL BIT 08MM 01.jpg"
import img90 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6059) GLASS DRILL BIT 16MM 01.jpg"
import img91 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6060) GLASS DRILL BIT 18MM 01.jpg"
import img92 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6061) GLASS DRILL BIT 20MM 01.jpg"
import img93 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6062) GLASS DRILL BIT 22MM 01.jpg"
import img94 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6063) GLASS DRILL BIT 25MM 01.jpg"
import img95 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6383) IRWIN SDS DRILL BIT 16X310MM NO-1 QUALTY 01.jpg"
import img96 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6644) BREAKER BIT - FLAT (65A) 01.jpg"
import img97 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6645) BREAKER BIT - NOSE (65A) 01.jpg"
import img98 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/(6788) maruthi star sds drill bit  6 x 160mm 01.jpg"
import img99 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/F WOOD BIT - (JBANDARI) 01.jpg"
import img100 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/WOOD DRILL BITWOOD DRILL BIT-(MAIKE TOOLS) 01.jpg"
import img101 from "/src/images/products/Lath Tools, Pad & Disc Items/Drill/WOOD SQUARE DRILL BIT -  KUDIMBI 01.jpg"
import img102 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(141) HSS HOLE SAW WITH ARBOR-19MM 01.jpg"
import img103 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(142) HSS HOLE SAW WITH ARBOR-20MM 01.jpg"
import img104 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(143) HSS HOLE SAW WITH ARBOR-22MM 01.jpg"
import img105 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(144) HSS HOLE SAW WITH ARBOR-25MM 01.jpg"
import img106 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(145) HSS HOLE SAW WITH ARBOR-32MM 01.jpg"
import img107 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(146) HSS HOLE SAW SETS (WOOD)-11PCS BOX 01.jpg"
import img108 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(147) HSS HOLE SAW SETS (WOOD)-8PCS BOX 01.jpg"
import img109 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(148) HSS HOLE SAW SETS (WOOD)-02PCS BOX 01.jpg"
import img110 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(580) Hss Hole Saw Sets (Wood)-05pcs Box.jpg"
import img111 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(851) HSS HOLE SAW WITH ARBOR-55MM 01.jpg"
import img112 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(852) HSS HOLE SAW WITH ARBOR-75MM 01.jpg"
import img113 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(853) HSS HOLE SAW WITH ARBOR-30MM 01.jpg"
import img114 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(921) HSS HOLE SAW WITH ARBOR-65MM 01.jpg"
import img115 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1510) HSS HOLE SAW WITH ARBOR-60MM 01.jpg"
import img116 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1514) TCT HOLE SAW WITH ARBOR - 19MM 01.jpg"
import img117 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1515) TCT HOLE SAW WITH ARBOR - 20MM 01.jpg"
import img118 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1516) TCT HOLE SAW WITH ARBOR - 22MM 01.jpg"
import img119 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1517) TCT HOLE SAW WITH ARBOR - 25MM 01.jpg"
import img120 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1518) TCT HOLE SAW WITH ARBOR - 30MM 01.jpg"
import img121 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1531) TCT HOLE SAW WITH ARBOR - 32MM 01.jpg"
import img122 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(1796) HSS HOLE SAW WITH ARBOR-16MM 01.jpg"
import img123 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2106) TCT HOLE SAW WITH ARBOR - 28MM 01.jpg"
import img124 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2107) TCT HOLE SAW WITH ARBOR - 38MM 01.jpg"
import img125 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2108) TCT HOLE SAW WITH ARBOR - 40MM 01.jpg"
import img126 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2109) TCT HOLE SAW WITH ARBOR - 50MM  01.jpg"
import img127 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2110) TCT HOLE SAW WITH ARBOR - 65MM 01.jpg"
import img128 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2111) TCT HOLE SAW WITH ARBOR - 75MM 01.jpg"
import img129 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2429) HSS HOLE SAW SETS (WOOD)-03PCS BOX 01.jpg"
import img130 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2440) HSS HOLE SAW (BI METAL)-19MM.jpg"
import img131 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2441) HSS HOLE SAW (BI METAL)-22MM 01.jpg"
import img132 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2442) HSS HOLE SAW (BI METAL)-25MM 01.jpg"
import img133 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2443) HSS HOLE SAW (BI METAL)-32MM 01.jpg"
import img134 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2444) HSS HOLE SAW (BI METAL)-50MM 01 - Copy.jpg"
import img135 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2445) HSS HOLE SAW (BI METAL)-14MM-30MM ARBOR 01.jpg"
import img136 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2553) TCT HOLE SAW WITH ARBOR - 60MM 01.jpg"
import img137 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2638) TCT HOLE SAW WITH ARBOR - 16MM FINE 01.jpg"
import img138 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2727) TCT HOLE SAW WITH ARBOR - 35MM 01.jpg"
import img139 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2728) TCT HOLE SAW WITH ARBOR - 45MM 01.jpg"
import img140 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(2860) TCT HOLE SAW WITH ARBOR - 13MM FINE 01.jpg"
import img141 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(3154) HSS HOLE SAW SETS (WOOD)-16PCS BOX 01.jpg"
import img142 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(5004) HSS HOLE SAW SETS (WOOD)-ADJUSTABLE 01.jpg"
import img143 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(5079) HSS HOLE SAW WITH ARBOR-35MM 01.jpg"
import img144 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(5080) HSS HOLE SAW WITH ARBOR-38MM 01.jpg"
import img145 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(5081) HSS HOLE SAW WITH ARBOR-40MM 01.jpg"
import img146 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(6287) HSS HOLE SAW WITH ARBOR-45MM 01.jpg"
import img147 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(6288) HSS HOLE SAW WITH ARBOR-50MM 01.jpg"
import img148 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/(6344) HSS HOLE SAW WITH ARBOR-28MM 01.jpg"
import img149 from "/src/images/products/Lath Tools, Pad & Disc Items/Hole Saw/TAKESHI ANNULAR CUTTER CU35S 01.jpg"
import img150 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(455) HSS DRILL BIT-PRESTO 1X8 01.jpg"
import img151 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(456) HSS DRILL BIT-PRESTO 5X32 01.jpg"
import img152 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(551) HSS DRILL BIT-GOLD 1X8 (10PCS BOX) 01.jpg"
import img153 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(555) HSS DRILL BIT-GOLD 1X2 (5PCS BOX) 01.jpg"
import img154 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(1671) HSS DRILL BIT-PVV M2 5X8 01.jpg"
import img155 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(2368) Hss Drill Bit Set 1.jpg"
import img156 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(2369) Hss Drill Bit Set 01.jpg"
import img157 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(2520) HSS DRILL BIT-PRESTO 3X8 01.jpg"
import img158 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(2520) HSS DRILL BIT-PRESTO 3X8 01.jpg"
import img159 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(2768) hss drill bit-gold 3 8x150mm 01.jpg"
import img160 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(3695) HSS DRILL BIT-1X2 SHANK 25MM (BLACK) 01.jpg"
import img161 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(3722) HSS DRILL BIT-GOLD 5X16 (10PCS BOX) 01.jpg"
import img162 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(5116) HSS DRILL BIT-PRESTO 12.5MM 01.jpg"
import img163 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(6763) HSS DRILL BIT-PVV FINE 3X16 (10PCS BOX) 01.jpg"
import img164 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(6767) HSS DRILL BIT-PVV FINE 5X16 (5PCS BOX) 01.jpg"
import img165 from "/src/images/products/Lath Tools, Pad & Disc Items/Hss Drill Bit/(6768) HSS DRILL BIT-PVV FINE 7X16 (5PCS BOX) 01.jpg"
import img166 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(895) BUFFING PAD ANGLE GRINDER 5 RED 01.jpg"
import img167 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(1285) GRINDER ADAPTER-SS POLISHING M10 (S) 01.jpg"
import img168 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(1286) GRINDER ADAPTER-SS POLISHING M14 (M) 01.jpg"
import img169 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(1287) GRINDER ADAPTER-SS POLISHING M14 (L) 01.jpg"
import img170 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(1519) SS CLOTH WHEEL 4 (WHITE) 01.jpg"
import img171 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(1829) POLISHING WHEEL-4 (WHITE) 01.jpg"
import img172 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2250) GRANITE POLISHING WHEEL (FINE)-0050 OXY 01.jpg"
import img173 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2251) GRANITE POLISHING WHEEL (FINE)-0100 OXY 01.jpg"
import img174 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2252) GRANITE POLISHING WHEEL (FINE)-0200 OXY 01.jpg"
import img175 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2253) GRANITE POLISHING WHEEL (FINE)-0400- OXY 001.jpg"
import img176 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2254) GRANITE POLISHING WHEEL (FINE)-0800 01.jpg"
import img177 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2255) GRANITE POLISHING WHEEL (FINE)-1000 01.jpg"
import img178 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2256) GRANITE POLISHING WHEEL (FINE)-1500 01.jpg"
import img179 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2257) GRANITE POLISHING WHEEL (FINE)-3000 01.jpg"
import img180 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(2258) GRANITE POLISHING WHEEL (FINE)-0600 01.jpg"
import img181 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(3160) AIR LINE WHEEL-SS BUFFING PAD 6 X 2 01.jpg"
import img182 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6156) BUFFING PAD ANGLE GRINDER 7 (WHITE) 01.jpg"
import img183 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6161) AIR LINE WHEEL-SS BUFFING PAD 4 01.jpg"
import img184 from "../../images/products/Lath Tools, Pad & Disc Items/Polish/(6203) GRANITE POLISHING WHEEL (FINE)-0150 OXY 01.jpg"
import img185 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6204) GRANITE POLISHING WHEEL (FINE)-0300 OXY 01.jpg"
import img186 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6205) GRANITE POLISHING WHEEL (FINE)-0500 OXY 01.jpg"
import img187 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6206) GRANITE POLISHING WHEEL (FINE)-1200 OXY 01.jpg"
import img188 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6207) GRANITE POLISHING WHEEL (FINE)-2000 OXY 01.jpg"
import img189 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6346) AIR LINE WHEEL-SS BUFFING PAD 4.5 01.jpg"
import img190 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/(6445) GRANITE POLISHING WHEEL (FINE)-0120 OXY 01.jpg"
import img191 from "/src/images/products/Lath Tools, Pad & Disc Items/Polish/2-(2933) BUFFING PAD A GRINDER 4.5 RED VELCRO TYPE 01.jpg"
import img192 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(220) SHARPENING OIL STONE-6 (1000GC) 01.jpg"
import img193 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(244) DIAMOND T.C.T SHARPENING WHEEL-FLAT 01.jpg"
import img194 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(245) DIAMOND T.C.T SHARPENING WHEEL-CUP 01.jpg"
import img195 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(1608) DIAMOND T.C.T SHARPENING WHEEL-6(F)2IN1 01.jpg"
import img196 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(1848) T.C.T. Sharpening Wheel 6 (Taper).jpg"
import img197 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(2104) 6 Oil Stone Tiger Mark Taiwan 01.jpg"
import img198 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(2772) DIAMOND SHARPENING WHEEL FLAT 8X16MM 01.jpg"
import img199 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(3723) SHARPENING WHEEL BOX - 4 TAPER TYPE 01.jpg"
import img200 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(3751) SHARPENING OIL STONE-6(180) TAIWAN 01.jpg"
import img201 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(3752) SHARPENING OIL STONE-6(240) TAIWAN 01.jpg"
import img202 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/(4001) SHARPENING OIL STONE-6 CUMI (NO-1) 01.jpg"
import img203 from "/src/images/products/Lath Tools, Pad & Disc Items/Sharpener/SHARPENING WHEEL BOX 01.jpg"
// image
import img204 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Handle/(3712) Tap & Die Set 20 Pcs Box.jpg"
import img205 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Handle/(5155) TAP HANDLE SET (1X16 - 1X4) 01.jpg"
import img206 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Handle/(5156) TAP HANDLE SET (1X16 - 3X8) 01.jpg"
import img207 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Handle/(5157) TAP HANDLE SET (1X16 - 1X2) 01.jpg"
import img208 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Handle/(6474) TAP HANDLE SET (1X16 - 3X4) 01.jpg"

import img209 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (Bsw)/(525) TAP SET 1x4 - (BSW) ALLPS BRAND 01.jpg"
import img210 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (Bsw)/(533) TAP SET 1 - (BSW) ALLPS BRAND 01.jpg"
import img211 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (Bsw)/(6019) TAP SET 1X8 - (BSW) ALLPS BRAND 01.jpg"

import img212 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (MM)/(520) TAP SET 12 X 1.5MM - ALLPS BRAND 01.jpg"
import img213 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (MM)/(650) TAP SET 04 X 0.7MM - ALLPS BRAND 01.jpg"
import img214 from "/src/images/products/Lath Tools, Pad & Disc Items/Tap Set (MM)/(2544) TAP SET 36 X 4.0MM - ALLPS BRAND 01.jpg"

import img215 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(246) electric vibrat motor-tile laying (12v) 01.jpg"
import img216 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(724) TILE LEVEL MEASURING DEVICE MANUAL 01.jpg"
import img217 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(1125) ENDCUTTING PLIER 7INCH 01.jpg"
import img218 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(1698) TILE ROUTER BEARING 12 01.jpg"
import img219 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(1885) HAND TILE CUTTER - 8 (IRON 01.jpg"
import img220 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(2174) TILE CUTTER WHEEL - 4 DIAMOND 01.jpg"
import img221 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(2571) TILE PUNCH LOCATOR - TOPVEI 01.jpg"
import img222 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(2819) TILE ROUTER WHEEL - 1X2 (LINE) 01.jpg"
import img223 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(3717) HAND TILE CUTTER - 8 01.jpg"
import img224 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/(6700) TILE ROUTER WHEEL - 1X2 01.jpg"
import img225 from "/src/images/products/Lath Tools, Pad & Disc Items/Tile/TILE ROUTER BIT 01.jpg"

import img226 from "/src/images/products/Lath Tools, Pad & Disc Items/Velcro Paper/VELCRO PAPER - 4 01.jpg"
import img227 from "/src/images/products/Lath Tools, Pad & Disc Items/Velcro Paper/VELCRO PAPER - 4.5 04001.jpg"
import img228 from "/src/images/products/Lath Tools, Pad & Disc Items/Velcro Paper/VELCRO PAPER - 5 01.jpg"
import img229 from "/src/images/products/Lath Tools, Pad & Disc Items/Velcro Paper/VELCRO PAPER - 7 01.jpg"
import img230 from "/src/images/products/Lath Tools, Pad & Disc Items/Velcro Paper/VELCRO PAPER - 8.5 (HOLE TYPE) 01.jpg"

import img231 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(970) WIRE BRUSH - ROUND TYPE 6 (IRON) 01.jpg"
import img232 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(1825) CUP 2.5 WIRE BRUSH - M10 (IRON) 01.jpg"
import img233 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(1826) CUP 2.5 WIRE BRUSH - M14 (IRON) 01.jpg"
import img234 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(1827) CUP 2.5 WIRE BRUSH - M10 (BRASS) 01.jpg"
import img235 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(1828) CUP 2.5 WIRE BRUSH - M14 (BRASS) 01.jpg"
import img236 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(2570) CUP 3 WIRE BRUSH - M10 (IRON) TWISTKNOT 01.jpg"
import img237 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(4071) CUP 3 WIRE BRUSH - M14 (IRON) TWISTKNOT 01.jpg"
import img238 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(4072) CUP 3 WIRE BRUSH - M10 (BRASS) CRIMPED 01.jpg"
import img239 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(4073) CUP 3 WIRE BRUSH - M14 (BRASS) CRIMPED 01.jpg"
import img240 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(5117) WIRE BRUSH - BRASS (5PCS SET) 01.jpg"
import img241 from "/src/images/products/Lath Tools, Pad & Disc Items/WIRE BRUSH/(5136) WIRE BRUSH - ROUND TYPE 6 (BRASS) 01.jpg"

import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"


const Chain = [
  {
    img:img1,
    name:"(1962) THREADING DIE HANDLE 34 (20 X 7)"
  },
  {
    img:img2,
    name:"(1964) THREADING DIE HANDLE 1 (25 X 9)"
  },
  {
    img:img3,
    name:"(1965) THREADING DIE HANDLE 1 14 (30 X 11)"
  },
  {
    img:img4,
    name:"(2096) THREADING DIE HANDLE 1 (INDIAN) 01"
  },
  {
    img:img5,
    name:"FIBER DISC 4 040"
  },
  {
    img:img6,
    name:"FIBER DISC 4.5 040 01"
  },
  {
    img:img7,
    name:"(221) MOUNTAIN STONE - SHANK BITS (10PCS CARD) New "
  },
{
    img:img8,
    name:"(234) CUP GRINDING WHEEL - 2 X 2 (MOUNTAIN)"
  },			
{
    img:img9,
    name:"(235) CUP GRINDING WHEEL - 4 X 2 (MOUNTAIN)"
  },
{
    img:img10,
    name:"(238) GRINDING WHEEL NUT - SMAL"
  },
{
    img:img11,
    name:"(239) GRINDING WHEEL NUT - LARGE"
  },
{
    img:img12,
    name:"(802) GRINDING WHEEL - WHITE 8 X 14 FLAT"
  },
{
    img:img13,
    name:"(803) GRINDING WHEEL - WHITE 8 X 12 FLAT"
  },
{
    img:img14,
    name:"(1220) BRANCA GRINDING WHEEL - 7 X 14"
  },
{
    img:img15,
    name:"(1830) BRANCA GRINDING WHEEL - 4 X 14"
  },
{
    img:img16,
    name:"(2050) BLACK GRINDING WHEEL - 4 X 46"
  },
{
    img:img17,
    name:"(2459) HAND GRINDER - GRINDING WHEEL 4"
  },
{
    img:img18,
    name:"(2840) CARBRIDE ROTARY FILE CX1425M-06"
  },
{
    img:img19,
    name:"(2843) CARBRIDE ROTARY FILE DX1412-M06"
  },
{
    img:img20,
    name:"(2844) CARBRIDE ROTARY FILE EX1016-M06"
  },
{
    img:img21,
    name:"(2848) CARBRIDE ROTARY FILE FX1225-M06"
  },
{
    img:img22,
    name:"(2851) CARBRIDE ROTARY FILE GX1225-M06"
  },
{
    img:img23,
    name:"(2853) CARBRIDE ROTARY FILE LX1025-M06"
  },
{
    img:img24,
    name:"(3750) FLAT GRINDING WHEEL - 5X12 (MOUNTAIN)"
  },
{
    img:img25,
    name:"(3753) GRINDING WHEEL - 6 X 1 (GREEN GC)"
  },
{
    img:img26,
    name:"(3754) GRINDING WHEEL - 8 X 1 (GREEN GC)"
  },
{
    img:img27,
    name:"(4076) MOUNTAIN STONE - SHANK BITS (5PCS CARD)"
  },
{
    img:img28,
    name:"(6246) grinding wheel - 8 x 1 (smooth)"
  },
{
    img:img29,
    name:"CARBRIDE ROTARY FILE MX"
  },
{
    img:img30,
    name:"GRINDING WHEEL - GREEN GC"
  },
  {
    img:img31,
    name:"(26) SDS PLUS DRILL BIT 14X240MM (NOSE)"
  },
  {
    img:img32,
    name:"SDS PLUS DRILL BIT 14X240MM (FLAT)"
  },
  {
    img:img33,
    name:"SDS PLUS DRILL BIT 260MM (BIG FLAT)"
  },
  {
    img:img34,
    name:"SDS MAX DRILL BIT 18X450MM (FLAT)"
  },
  {
    img:img35,
    name:"SDS MAX DRILL BIT 18X450MM (NOSE)"
  },
  {
    img:img36,
    name:"IRWIN MATIONARY DRILL BIT 05MM"
  },
  {
    img:img37,
    name:"IRWIN MATIONARY DRILL BIT 08MM"
  },
{
    img:img38,
    name:"IRWIN MATIONARY DRILL BIT 10MM"
  },			
{
    img:img39,
    name:"F WOOD BIT SET - 6PCS (JBANDARI)"
  },
{
    img:img40,
    name:"DIAMOND DRILL BIT 35MM ORANGE (ZHIWEI)"
  },
  {
    img:img41,
    name:"DIAMOND DRILL BIT 40MM ORANGE (ZHIWEI)"
  },
  {
    img:img42,
    name:"DIAMOND DRILL BIT 45MM ORANGE (ZHIWEI)"
  },
  {
    img:img43,
    name:"DIAMOND DRILL BIT 50MM ORANGE (ZHIWEI)"
  },
  {
    img:img44,
    name:"GLASS DRILL BIT 14MM"
  },
  {
    img:img45,
    name:"SKOLE SDS DRILL BIT 14X250MM"
  },
  {
    img:img46,
    name:"SDS Breaker Bit"
  },
  {
    img:img47,
    name:"DIAMOND CORE BIT 2.5 (63MM)"
  },
{
    img:img48,
    name:"DIAMOND CORE BIT 3 (76MM)"
  },			
{
    img:img49,
    name:"DIAMOND CORE BIT 3.5 (83MM)"
  },
{
    img:img50,
    name:"DIAMOND CORE BIT 4 (102MM)"
  },
  {
    img:img51,
    name:"DIAMOND CORE BIT ADAPTER (230MM)"
  },
  {
    img:img52,
    name:"GLASS & TILE DRILL BIT 14MM (FINE)"
  },
  {
    img:img53,
    name:"GLASS DRILL BIT 32MM"
  },
  {
    img:img54,
    name:"DIAMOND DRILL BIT 06MM ORANGE (ZHIWEI)"
  },
  {
    img:img55,
    name:"DIAMOND DRILL BIT 08MM ORANGE (ZHIWEI)"
  },
  {
    img:img56,
    name:"DIAMOND DRILL BIT 10MM ORANGE (ZHIWEI)"
  },
  {
    img:img57,
    name:"DIAMOND DRILL BIT 12MM  ORANGE (ZHIWEI)"
  },
{
    img:img58,
    name:"DIAMOND DRILL BIT 14MM ORANGE (ZHIWEI)"
  },			
{
    img:img59,
    name:"DIAMOND DRILL BIT 16MM  ORANGE (ZHIWEI)"
  },
{
    img:img60,
    name:"DIAMOND DRILL BIT 20MM ORANGE (ZHIWEI)"
  },
  {
    img:img61,
    name:"DIAMOND DRILL BIT 22MM ORANGE (ZHIWEI)"
  },
  {
    img:img62,
    name:"DIAMOND DRILL BIT 25MM ORANGE (ZHIWEI)"
  },
  {
    img:img63,
    name:"DIAMOND DRILL BIT 28MM ORANGE (ZHIWEI)"
  },
  {
    img:img64,
    name:"DIAMOND DRILL BIT 30MM ORANGE (ZHIWEI)"
  },
  {
    img:img65,
    name:"DIAMOND DRILL BIT 32MM ORANGE (ZHIWEI)"
  },
  {
    img:img66,
    name:"DIAMOND CORE BIT ADAPTER (400MM)"
  },
  {
    img:img67,
    name:"DIAMOND CORE BIT 1 (25MM)"
  },
{
    img:img68,
    name:"DIAMOND CORE BIT 1.5 (38MM)"
  },			
{
    img:img69,
    name:"WHITE PACK MAX DRILL BIT 30X800MM REEKE"
  },
{
    img:img70,
    name:"DIAMOND CORE BIT 6 (152MM)"
  },
  {
    img:img71,
    name:"DRILL BIT ADAPTER MT3"
  },
  {
    img:img72,
    name:"DIAMOND CORE BIT 5 (127MM)"
  },
  {
    img:img73,
    name:"DIAMOND DRILL BIT 18MM  ORANGE (ZHIWEI)"
  },
  {
    img:img74,
    name:"DIAMOND CORE BIT ADAPTER (160MM)"
  },
  {
    img:img75,
    name:"SKOLE SDS DRILL BIT 14X250MM (N) NORMAL"
  },
  {
    img:img76,
    name:"CORE BIT ADAPTER (REDUCER)"
  },
  {
    img:img77,
    name:"DIAMOND CORE BIT 4 (102MM) OXY HAVY"
  },
{
    img:img78,
    name:"SDS PLUS SDS DRILL BIT 14X450MM (FLAT)"
  },			
{
    img:img79,
    name:"SDS PLUS SDS DRILL BIT 14X450MM (NOSE)"
  },
{
    img:img80,
    name:"IRWIN SDS DRILL BIT 07X110MM NO-1 QUALTY"
  },
  {
    img:img81,
    name:"IRWIN SDS DRILL BIT 14X160MM NO-1 QUALTY"
  },
  {
    img:img82,
    name:"SDS HEX DRILL BIT 17MM X 280MM (FLAT)"
  },
  {
    img:img83,
    name:"SDS HEX DRILL BIT 17MM X 280MM (NOSE)"
  },
  {
    img:img84,
    name:"GLASS DRILL BIT 10MM"
  },
  {
    img:img85,
    name:"GLASS DRILL BIT 12MM"
  },
  {
    img:img86,
    name:"GLASS DRILL BIT 30MM"
  },
  {
    img:img87,
    name:"GLASS DRILL BIT 06MM"
  },
{
    img:img88,
    name:"GLASS DRILL BIT 07MM"
  },			
{
    img:img89,
    name:"GLASS DRILL BIT 08MM"
  },
{
    img:img90,
    name:"GLASS DRILL BIT 16MM"
  },
  {
    img:img91,
    name:"GLASS DRILL BIT 18MM"
  },
  {
    img:img92,
    name:"GLASS DRILL BIT 20MM"
  },
  {
    img:img93,
    name:"GLASS DRILL BIT 22MM"
  },
  {
    img:img94,
    name:"GLASS DRILL BIT 25MM"
  },
  {
    img:img95,
    name:"IRWIN SDS DRILL BIT 16X310MM NO-1 QUALTY"
  },
  {
    img:img96,
    name:"BREAKER BIT - FLAT (65A)"
  },
  {
    img:img97,
    name:"BREAKER BIT - NOSE (65A)"
  },
{
    img:img98,
    name:"Maruthi star sds drill bit  6 x 160mm"
  },			
{
    img:img99,
    name:"F WOOD BIT - (JBANDARI)"
  },
{
    img:img100,
    name:"WOOD DRILL BITWOOD DRILL BIT-(MAIKE TOOLS)"
  },
{
    img:img101,
    name:"WOOD SQUARE DRILL BIT -  KUDIMBI"
  },

  {
    img:img102,
    name:"HSS HOLE SAW WITH ARBOR-19MM"
  },
  {
    img:img103,
    name:"HSS HOLE SAW WITH ARBOR-20MM"
  },
  {
    img:img104,
    name:"HSS HOLE SAW WITH ARBOR-22MM"
  },
  {
    img:img105,
    name:"HSS HOLE SAW WITH ARBOR-25MM"
  },
  {
    img:img106,
    name:"HSS HOLE SAW WITH ARBOR-32MM"
  },
  {
    img:img107,
    name:"HSS HOLE SAW SETS (WOOD)-11PCS BOX"
  },
  {
    img:img108,
    name:"HSS HOLE SAW SETS (WOOD)-8PCS BOX"
  },
{
    img:img109,
    name:"HSS HOLE SAW SETS (WOOD)-02PCS BOX"
  },			
{
    img:img110,
    name:"Hss Hole Saw Sets (Wood)-05pcs Box"
  },
{
    img:img111,
    name:"HSS HOLE SAW WITH ARBOR-55MM"
  },
{
    img:img112,
    name:"HSS HOLE SAW WITH ARBOR-75MM"
  },
{
    img:img113,
    name:"HSS HOLE SAW WITH ARBOR-30MM"
  },
{
    img:img114,
    name:"HSS HOLE SAW WITH ARBOR-65MM"
  },
{
    img:img115,
    name:"HSS HOLE SAW WITH ARBOR-60MM"
  },
{
    img:img116,
    name:"TCT HOLE SAW WITH ARBOR - 19MM"
  },
{
    img:img117,
    name:"TCT HOLE SAW WITH ARBOR - 20MM"
  },
{
    img:img118,
    name:"TCT HOLE SAW WITH ARBOR - 22MM"
  },
{
    img:img119,
    name:"TCT HOLE SAW WITH ARBOR - 25MM"
  },
{
    img:img120,
    name:"TCT HOLE SAW WITH ARBOR - 30MM"
  },
{
    img:img121,
    name:"TCT HOLE SAW WITH ARBOR - 32MM"
  },
{
    img:img122,
    name:"HSS HOLE SAW WITH ARBOR-16MM"
  },
{
    img:img123,
    name:"TCT HOLE SAW WITH ARBOR - 28MM"
  },
{
    img:img124,
    name:"TCT HOLE SAW WITH ARBOR - 38MM"
  },
{
    img:img125,
    name:"TCT HOLE SAW WITH ARBOR - 40MM"
  },
{
    img:img126,
    name:"TCT HOLE SAW WITH ARBOR - 50MM"
  },
{
    img:img127,
    name:"TCT HOLE SAW WITH ARBOR - 65MM"
  },
{
    img:img128,
    name:"TCT HOLE SAW WITH ARBOR - 75MM"
  },
{
    img:img129,
    name:"HSS HOLE SAW SETS (WOOD)-03PCS BOX"
  },
{
    img:img130,
    name:"HSS HOLE SAW (BI METAL)-19MM"
  },
{
    img:img131,
    name:"HSS HOLE SAW (BI METAL)-22MM"
  },
  {
    img:img132,
    name:"HSS HOLE SAW (BI METAL)-25MM"
  },
  {
    img:img133,
    name:"HSS HOLE SAW (BI METAL)-32MM"
  },
  {
    img:img134,
    name:"HSS HOLE SAW (BI METAL)-50MM"
  },
  {
    img:img135,
    name:"HSS HOLE SAW (BI METAL)-14MM-30MM ARBOR"
  },
  {
    img:img136,
    name:"TCT HOLE SAW WITH ARBOR - 60MM"
  },
  {
    img:img137,
    name:"TCT HOLE SAW WITH ARBOR - 16MM"
  },
  {
    img:img138,
    name:"TCT HOLE SAW WITH ARBOR - 35MM"
  },
{
    img:img139,
    name:"TCT HOLE SAW WITH ARBOR - 45MM"
  },			
{
    img:img140,
    name:"TCT HOLE SAW WITH ARBOR - 13MM"
  },
{
    img:img141,
    name:"HSS HOLE SAW SETS (WOOD)-16PCS BOX"
  },
  {
    img:img142,
    name:"HSS HOLE SAW SETS (WOOD)-ADJUSTABLE"
  },
  {
    img:img143,
    name:"HSS HOLE SAW WITH ARBOR-35MM"
  },
  {
    img:img144,
    name:"HSS HOLE SAW WITH ARBOR-38MM"
  },
  {
    img:img145,
    name:"HSS HOLE SAW WITH ARBOR-40MM"
  },
  {
    img:img146,
    name:"HSS HOLE SAW WITH ARBOR-45MM"
  },
  {
    img:img147,
    name:"HSS HOLE SAW WITH ARBOR-50MM"
  },
  {
    img:img148,
    name:"HSS HOLE SAW WITH ARBOR-28MM"
  },
{
    img:img149,
    name:"TAKESHI ANNULAR CUTTER CU35S"
  },
  {
    img:img150,
    name:"HSS DRILL BIT-PRESTO 1X8"
  },
  {
    img:img151,
    name:"HSS DRILL BIT-PRESTO 5X32"
  },
  {
    img:img152,
    name:"HSS DRILL BIT-GOLD 1X8 (10PCS BOX)"
  },
  {
    img:img153,
    name:"HSS DRILL BIT-GOLD 1X2 (5PCS BOX)"
  },
  {
    img:img154,
    name:"HSS DRILL BIT-PVV M2 5X8"
  },
  {
    img:img155,
    name:"Hss Drill Bit Set"
  },
  {
    img:img156,
    name:"Hss Drill Bit Set"
  },
  {
    img:img157,
    name:"HSS DRILL BIT-PRESTO 3X8"
  },
  {
    img:img158,
    name:"HSS DRILL BIT-PRESTO 3X8"
  },
  {
    img:img159,
    name:"hss drill bit-gold 3 8x150mm"
  },
  {
    img:img160,
    name:"HSS DRILL BIT-1X2 SHANK 25MM"
  },
  {
    img:img161,
    name:"HSS DRILL BIT-GOLD 5X16 (10PCS BOX)"
  },
  {
    img:img162,
    name:"HSS DRILL BIT-PRESTO 4.5MM 01(5116)"
  },
  {
    img:img163,
    name:"HSS DRILL BIT-PVV FINE 3X16 (10PCS BOX)"
  },
  {
    img:img164,
    name:" HSS DRILL BIT-PVV FINE 5X16 (5PCS BOX)"
  },
  {
    img:img165,
    name:"HSS DRILL BIT-PVV FINE 7X16 (5PCS BOX)"
  },
  {
    img:img166,
    name:"BUFFING PAD ANGLE GRINDER 5 RED"
  },
  {
    img:img167,
    name:"GRINDER ADAPTER-SS POLISHING M10 (S)"
  },
  {
    img:img168,
    name:"GRINDER ADAPTER-SS POLISHING M14 (M)"
  },
  {
    img:img169,
    name:"GRINDER ADAPTER-SS POLISHING M14 (L)"
  },
 {
    img:img170,
    name:"SS CLOTH WHEEL 4 (WHITE)"
  },
  {
    img:img171,
    name:"POLISHING WHEEL-4 (WHITE)"
  },
  {
    img:img172,
    name:"GRANITE POLISHING WHEEL (FINE)-0050# OXY"
  },
  {
    img:img173,
    name:"GRANITE POLISHING WHEEL (FINE)-0100# OXY"
  },
 {
    img:img174,
    name:"GRANITE POLISHING WHEEL (FINE)-0200 OXY"
  },
  {
    img:img175,
    name:"GRANITE POLISHING WHEEL (FINE)-0400- OXY"
  },
  {
    img:img176,
    name:"GRANITE POLISHING WHEEL (FINE)-0800"
  },
  {
    img:img177,
    name:"GRANITE POLISHING WHEEL (FINE)-1000"
  },
 {
    img:img178,
    name:"GRANITE POLISHING WHEEL (FINE)-1500"
  },
  {
    img:img179,
    name:"GRANITE POLISHING WHEEL (FINE)-3000"
  },
  {
    img:img180,
    name:"GRANITE POLISHING WHEEL (FINE)-0600"
  },
  {
    img:img181,
    name:"AIR LINE WHEEL-SS BUFFING PAD 6 X 2"
  },
 {
    img:img182,
    name:"BUFFING PAD ANGLE GRINDER 7 (WHITE)"
  },
  {
    img:img183,
    name:"AIR LINE WHEEL-SS BUFFING PAD"
  },
  {
    img:img184,
    name:"GRANITE POLISHING WHEEL (FINE)-0150# OXY"
  },
  {
    img:img185,
    name:"GRANITE POLISHING WHEEL (FINE)-0300# OXY"
  },
 {
    img:img186,
    name:"GRANITE POLISHING WHEEL (FINE)-0500# OXY"
  },
  {
    img:img187,
    name:"GRANITE POLISHING WHEEL (FINE)-1200# OXY"
  },
  {
    img:img188,
    name:"GRANITE POLISHING WHEEL (FINE)-2000# OXY"
  },
  {
    img:img189,
    name:"AIR LINE WHEEL-SS BUFFING PAD"
  },
 {
    img:img190,
    name:"GRANITE POLISHING WHEEL (FINE)-0120# OXY"
  },
  {
    img:img191,
    name:"BUFFING PAD A GRINDER 4.5 RED VELCRO TYPE"
  },
  {
    img:img192,
    name:" SHARPENING OIL STONE-6 (1000GC)"
  },
 {
    img:img193,
    name:"DIAMOND T.C.T SHARPENING WHEEL-FLAT"
  },
 {
    img:img194,
    name:"DIAMOND T.C.T SHARPENING WHEEL-CUP"
  },
 {
    img:img195,
    name:"DIAMOND T.C.T SHARPENING WHEEL-6(F)2IN1"
  },
 {
    img:img196,
    name:"T.C.T. Sharpening Wheel 6 (Taper)"
  },
 {
    img:img197,
    name:"6 Oil Stone Tiger Mark Taiwan"
  },
 {
    img:img198,
    name:"DIAMOND SHARPENING WHEEL FLAT 8X16MM"
  },
 {
    img:img199,
    name:"SHARPENING WHEEL BOX - 4 TAPER TYPE"
  },
 {
    img:img200,
    name:"SHARPENING OIL STONE-6(180#) TAIWAN"
  },
 {
    img:img201,
    name:"SHARPENING OIL STONE-6(240#) TAIWAN"
  },
 {
    img:img202,
    name:"SHARPENING OIL STONE-6 CUMI (NO-1)"
  },
 {
    img:img203,
    name:"SHARPENING WHEEL BOX"
  },
  {
    img:img204,
    name:" Tap & Die Set 20 Pcs Box"
  },
{
    img:img205,
    name:"TAP HANDLE SET (1X16 - 1X4)"
  },
{
    img:img206,
    name:"TAP HANDLE SET (1X16 - 3X8)"
  },
{
    img:img207,
    name:"TAP HANDLE SET (1X16 - 1X2)"
  },
{
    img:img208,
    name:"TAP HANDLE SET (1X16 - 3X4)"
  },
{
    img:img209,
    name:"TAP SET 1x4 - (BSW) ALLPS BRAND"
  },
{
    img:img210,
    name:"TAP SET 1 - (BSW) ALLPS BRAND"
  },
{
    img:img211,
    name:"TAP SET 1X8 - (BSW) ALLPS BRAND"
  },
{
    img:img212,
    name:"TAP SET 12 X 1.5MM - ALLPS BRAND"
  },
{
    img:img213,
    name:"TAP SET 04 X 0.7MM - ALLPS BRAND"
  },
{
    img:img214,
    name:"TAP SET 36 X 4.0MM - ALLPS BRAND"
  },
{
    img:img215,
    name:"Electric vibrat motor-tile laying (12v)"
  },
{
    img:img216,
    name:"TILE LEVEL MEASURING DEVICE MANUAL"
  },
{
    img:img217,
    name:"ENDCUTTING PLIER 7INCH"
  },
{
    img:img218,
    name:"TILE ROUTER BEARING 12"
  },
{
    img:img219,
    name:"HAND TILE CUTTER - 8"
  },
{
    img:img220,
    name:"TILE CUTTER WHEEL - 4"
  },
{
    img:img221,
    name:"TILE PUNCH LOCATOR - TOPVEI"
  },
{
    img:img222,
    name:"TILE ROUTER WHEEL - 1X2 (LINE)"
  },
{
    img:img223,
    name:"HAND TILE CUTTER"
  },
{
    img:img224,
    name:"TILE ROUTER WHEEL"
  },
{
    img:img225,
    name:"TILE ROUTER BIT"
  },
{
    img:img226,
    name:"VELCRO PAPER - 4"
  },
{
    img:img227,
    name:"VELCRO PAPER - 4"
  },
{
    img:img228,
    name:"VELCRO PAPER - 5"
  },
{
    img:img229,
    name:"VELCRO PAPER - 7"
  },
{
    img:img230,
    name:"VELCRO PAPER - 8.5 (HOLE TYPE)"
  },
{
    img:img231,
    name:"WIRE BRUSH - ROUND TYPE 6 (IRON)"
  },
{
    img:img232,
    name:"CUP 2.5 WIRE BRUSH - M10 (IRON)"
  },
{
    img:img233,
    name:"CUP 2.5 WIRE BRUSH - M14 (IRON)"
  },
{
    img:img234,
    name:"CUP 2.5 WIRE BRUSH - M10 (BRASS)"
  },
{
    img:img235,
    name:"CUP 2.5 WIRE BRUSH - M14 (BRASS)"
  },
{
    img:img236,
    name:"CUP 3 WIRE BRUSH - M10 (IRON) TWISTKNOT"
  },
{
    img:img237,
    name:"CUP 3 WIRE BRUSH - M14 (IRON) TWISTKNOT"
  },
{
    img:img238,
    name:"CUP 3 WIRE BRUSH - M10 (BRASS) CRIMPED"
  },
{
    img:img239,
    name:"CUP 3 WIRE BRUSH - M14 (BRASS) CRIMPED"
  },
{
    img:img240,
    name:"WIRE BRUSH - BRASS (5PCS SET)"
  },
{
    img:img241,
    name:"WIRE BRUSH - ROUND TYPE 6 (BRASS)"
  },
]

function ChainSaw() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };

  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {Chain.map((name, index) => (
              <a  className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={Chain[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {Chain[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default ChainSaw
