import React from "react"
import Layout from "../components/layout"
import ToolsSlider from "../components/tools"
import { Link } from "gatsby"
import Agriculture from "../components/product/Agriculture"
import AirCompressor from "../components/product/AirCompressor"
import ChainBlocks from "../components/product/ChainBlocks"
import ChainSaw from "../components/product/ChainSaw"
import CuttingRotary from "../components/product/CuttingRotary"
import Industrial from "../components/product/Industrial"
import NewProducts from "../components/product/NewProducts"
import PowerTools from "../components/product/PowerTools"
import WoodTools from "../components/product/woodTools"
import WeldingTools from "../components/product/WeldingTools"
// import img1 from "../images/Catalogue-photos/Air Compressor and Air Tools/(6402) AC HEAD DOUBLE PISTON FULL SET- 60-100L.jpg"
// import img2 from "../images/Catalogue-photos/Air Compressor and Air Tools/(6403) Air Compressor Double Piston 50 Ltr.jpg"
// import img3 from "../images/Catalogue-photos/Air Compressor and Air Tools/(6457) AIR NAIL GUN F-30 (RED BOX) 01.jpg"
// import img4 from "../images/Catalogue-photos/Air Compressor and Air Tools/(6459) AIR NAIL GUN T-50 (RED BOX).jpg"
// import img5 from "../images/Catalogue-photos/Power Tools & Spare Parts/Armature/(204) ARMATURE ANGLE GRINDER (MOD 9553) 01.jpg"
// import img6 from "../images/Catalogue-photos/Power Tools & Spare Parts/Armature/(205) ARMATURE ANGLE GRINDER (MOD 9523) 01.jpg"
// import img7 from "../images/Catalogue-photos/Power Tools & Spare Parts/Armature/(206) ARMATURE ANGLE GRINDER (MOD-9911) 4X1X2 01.jpg"
// import img8 from "../images/Catalogue-photos/Power Tools & Spare Parts/Armature/(927) ARMATURE ANGLE GRINDER MOD-9911 THREAD 4 01.jpg"
// import img9 from "../images/Catalogue-photos/Cutting Rotary & Tile/Circularsaw Blades/(1929) KRALL CSAW BLADE 16 X 36T 01.jpg"
// import img10 from "../images/Catalogue-photos/Cutting Rotary & Tile/Circularsaw Blades/(1943) KRALL CSAW BLADE 10 X 24T 01.jpg"
// import img11 from "../images/Catalogue-photos/Cutting Rotary & Tile/Circularsaw Blades/(2885) VEIBER C-SAW BLADE 7X40T 01.jpg"
// import img12 from "../images/Catalogue-photos/Cutting Rotary & Tile/Circularsaw Blades/(2896) KRALL CSAW BLADE 10 X 40T 01.jpg"
// import img13 from "../images/Catalogue-photos/Air Compressor and Air Tools/(114) AIR SPRAY GUN F-75G (2PCS).jpg"
// import img14 from "../images/Catalogue-photos/Air Compressor and Air Tools/(115) AIR SPRAY GUN K-3 (ORANGE BOX) 01.jpg"
// import img15 from "../images/Catalogue-photos/Air Compressor and Air Tools/(140) AIR OIL SPRAY GUN - RED (2PCS BOX) 01.jpg"
// import img16 from "../images/Catalogue-photos/Air Compressor and Air Tools/(292) Air Foam Blaster Gun (p6- 9.2bar) 01.jpg"

// const AllProduct = [
//   {
//     img:img1,
//     name:"Ac head double piston full set- 60-100l"
//   },
//   {
//     img:img2,
//     name:"Air compressor double piston 50 ltr"
//   },
//   {
//     img:img3,
//     name:"Air nail gun f-30 (red box)"
//   },
//   {
//     img:img4,
//     name:"Air nail gun t-50 (red box)"
//   },
//   {
//     img:img5,
//     name:"Armature angle grinder (mod 9553)"
//   },{
//     img:img6,
//     name:"Armature angle grinder (mod 9523)"
//   },
//   {
//     img:img7,
//     name:"Armature angle grinder (mod-9911)"
//   },
//   {
//     img:img8,
//     name:"Armature angle grinder mod-9911 thread"
//   },{
//     img:img9,
//     name:"Krall csaw blade 16 x 36t"
//   },
//   {
//     img:img10,
//     name:"Krall csaw blade 10 x 24t"
//   },
// // 20start
//   {
//     img:img11,
//     name:"Veiber c-saw blade 7x40t"
//   },
//   {
//     img:img12,
//     name:"Krall csaw blade 10 x 40t"
//   },
//   {
//     img:img13,
//     name:"Air spray gun f-75g (2pcs)"
//   },
//   {
//     img:img14,
//     name:"Air spray gun k-3 (orange box)"
//   },
//   {
//     img:img15,
//     name:"Air oil spray gun - red (2pcs box)"
//   },{
//     img:img16,
//     name:"Air foam blaster gun (p6- 9.2bar)"
//   },

// ]

function Products() {
  return(
    <Layout>
      <div id="productSlider">
        <ToolsSlider/>
      </div>


      {/* <div className="container text-center">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            What we have for you
          </p>
          <h2>
            Other Products
          </h2>
        </div>

        <div className="product-info-wrapper row">
          {AllProduct.map((name, index) => (
            <div className="col-lg-3">
              <a href="#" className="product-link">
                <div key={index} className="bg-light product-slider-content">
                  <div className="product-img">
                    <img src={AllProduct[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {AllProduct[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>

        <a type="button" className="find-more-product" href="#nextTool">More</a>
      </div> */}

      <div className="container" id="nextTool">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Agriculture Machineries
          </h2>
        </div>
        <Agriculture/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Air Compressor and Air Tools
          </h2>
        </div>
        <AirCompressor/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Power Tool
          </h2>
       </div>
        <PowerTools/>
      </div>

      
      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Lathe Tools
          </h2>
        </div>
        <ChainSaw/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Industrial Tools
          </h2>
        </div>
        <Industrial/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Safety Equipment
          </h2>
        </div>
        <ChainBlocks/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our products
          </p>
          <h2>
            Hand Tool
          </h2>
        </div>
        <CuttingRotary/>
      </div>


      <div className="container">
        <div className="fine-headline text-uppercase">
          <p className=''>
            Discover our new products
          </p>
          <h2>
            General 
          </h2>
        </div>
        <NewProducts/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase">
          <p className=''>
            Discover our new products
          </p>
          <h2>
            Wood Working
          </h2>
        </div>
        <WoodTools/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase">
          <p className=''>
            Discover our new products
          </p>
          <h2>
          Welding Working
          </h2>
        </div>
        <WeldingTools/>
      </div>

    </Layout>
  )
}

export default Products
