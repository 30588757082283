import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images
import img1 from "/src/images/products/Agriculture/Chain Saw/(1868) K3 Chain Saw Machine (Electric).jpg"
import img2 from "/src/images/products/Agriculture/Chain Saw/(883)(885) Chainsaw head for grass cutter.jpg"
import img3 from "/src/images/products/Agriculture/Chain Saw/(1997) Chain Saw 5800 (Gasoline).jpg"
import img4 from "/src/images/products/Agriculture/Chain Saw/(2139) Chain Saw 2500 (Gasoline).jpg"
import img5 from "/src/images/products/Agriculture/Chain Saw/(2545) Pole Chainsaw 520.jpg"

import img6 from "/src/images/products/Agriculture/Earth Auger/(110) EARTH AUGER-68CC Machine and bits.jpg"
import img7 from "/src/images/products/Agriculture/Earth Auger/(2284)(2431) EARTH AUGER-55CC Machine and bits.jpg"
import img8 from "/src/images/products/Agriculture/Earth Auger/(2601) EARTH AUGER-75CC Machine and bits.jpg"

import img9 from "/src/images/products/Agriculture/Grass Cutter/(187) Grass cutter - (Trim-338) U.S.A.jpg"
import img10 from "/src/images/products/Agriculture/Grass Cutter/(929) Grass cutter - BG-328.jpg"
import img11 from "/src/images/products/Agriculture/Grass Cutter/(1859) Grass cutter - CG-260 (Fine).jpg"
import img12 from "/src/images/products/Agriculture/Grass Cutter/(2828) Grass cutter -12v Rechargeable.jpg"
import img13 from "/src/images/products/Agriculture/Grass Cutter/(2980) Grass cutter GX35 - (4 Stroke).jpg"

import img14 from "/src/images/products/Agriculture/Lawn Mower/(2141)(3036) Hand push Lawn mower 14&16.jpg"
import img15 from "/src/images/products/Agriculture/Lawn Mower/(2424) Lawn mower-electric (1200w).jpg"
import img16 from "/src/images/products/Agriculture/Lawn Mower/(2733) Lawn mower-engine type16 (DCM1663)135cc.jpg"

import img17 from "/src/images/products/Agriculture/Mini Tiller/(1357) MINI TILLER (SMALL).jpg"
import img18 from "/src/images/products/Agriculture/Mini Tiller/(2660) MINI TILLER.jpg"

import img19 from "/src/images/products/Agriculture/Sprayer/(637) Sprayer Tank - Rechargeable (2 In 1) 16ltr.jpg"
import img20 from "/src/images/products/Agriculture/Sprayer/(856) Engine Sprayer 767 - 20ltr.jpg"
import img21 from "/src/images/products/Agriculture/Sprayer/(884) Sprayer Tank 16ltr.jpg"
import img22 from "/src/images/products/Agriculture/Sprayer/(1232) Bottle Sprayer 10ltr.jpg"
import img23 from "/src/images/products/Agriculture/Sprayer/(1382) Bottle Sprayer 02ltr.jpg"
import img24 from "/src/images/products/Agriculture/Sprayer/(1383) Bottle Sprayer 05ltr.jpg"
import img25 from "/src/images/products/Agriculture/Sprayer/(1945) Sprayer Tank (Heavy) 16ltr.jpg"
import img26 from "/src/images/products/Agriculture/Sprayer/(3119) Bottle Sprayer-Rechargeable 16ltr.jpg"

import img27 from "/src/images/products/Agriculture/Tea Cuttinge/(2142) Tea plucking Machine (Gasoline).jpg"
import img28 from "/src/images/products/Agriculture/Tea Cuttinge/(3141) Tea plucking machine (Electric).jpg"

import img29 from "/src/images/products/Agriculture/Water Pump/(652) Submersible pump Qdx3-20-0.55 -1.jpg"
import img30 from "/src/images/products/Agriculture/Water Pump/(740) Automatic Pump Controller.jpg"
import img31 from "/src/images/products/Agriculture/Water Pump/(1231) Water pump Gasoline (4stroke).jpg"
import img32 from "/src/images/products/Agriculture/Water Pump/(1818) SUBMERSIBLE WATER PUMP - 2.jpg"
import img33 from "/src/images/products/Agriculture/Water Pump/(2432)(2433) Water pump CPM-130 & 158 -1.jpg"
import img34 from "/src/images/products/Agriculture/Water Pump/(2435) Water pump Qb-60 -1.jpg"
import img35 from "/src/images/products/Agriculture/Water Pump/(2457) Submersible pump Qdx - 1.jpg"
import img36 from "/src/images/products/Agriculture/Water Pump/(2458) Submersible pump 50WQDJ15-13-1.5F - 2.jpg"
import img37 from "/src/images/products/Agriculture/Water Pump/(2679) Pump LSWQB-12 (DC-12V).jpg"
import img38 from "/src/images/products/Agriculture/Water Pump/(2685)(1701) Water pump (Petrol start) Kerosene.jpg"
import img39 from "/src/images/products/Agriculture/Water Pump/(2954) Tube Well Pump 24v.jpg"
import img40 from "/src/images/products/Agriculture/Water Pump/(3016) Water Pressure Testing Pump.jpg"
import img41 from "/src/images/products/Agriculture/Water Pump/(6255)(6628) Water pump Gasoline (2stroke).jpg"
import img42 from "/src/images/products/Agriculture/Water Pump/(6453) Water Pump-QB-60.jpg"
import img43 from "/src/images/products/Agriculture/Water Pump/TUBE WELL WATER PUMP.jpg"
import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"




const agriculture = [
  {
    img:img1,
    name:"(1868) K3 Chain Saw Machine (Electric)"
  },
  {
    img:img2,
    name:"(883)(885) Chainsaw head for grass cutter"
  },
  {
    img:img3,
    name:"(1997) Chain Saw 5800 (Gasoline)"
  },
  {
    img:img4,
    name:"(2139) Chain Saw 2500 (Gasoline)"
  },
  {
    img:img5,
    name:"(2545) Pole Chainsaw 520"
  },
  {
    img:img6,
    name:"(110) EARTH AUGER-68CC Machine and bits"
  },

  {
    img:img7,
    name:"(2284)(2431) EARTH AUGER-55CC Machine and bits"
  },
  {
    img:img8,
    name:"(2601) EARTH AUGER-75CC Machine and bits"
  },

  {
    img:img9,
    name:"(187) Grass cutter - (Trim-338) U.S.A"
  },
  {
    img:img10,
    name:"(929) Grass cutter - BG-328"
  },
  {
    img:img11,
    name:"(1859) Grass cutter - CG-260 (Fine)"
  },
  {
    img:img12,
    name:"(2828) Grass cutter -12v Rechargeable"
  },
  {
    img:img13,
    name:"(2980) Grass cutter GX35 - (4 Stroke)"
  },

  {
    img:img14,
    name:"(2141)(3036) Hand push Lawn mower 14&16"
  },
  {
    img:img15,
    name:"(2424) Lawn mower-electric (1200w)"
  },
  {
    img:img16,
    name:"(2733) Lawn mower-engine type16 (DCM1663)135cc"
  },

  {
    img:img17,
    name:"(1357) MINI TILLER (SMALL)"
  },
  {
    img:img18,
    name:"(2660) MINI TILLER"
  },
  {
    img:img19,
    name:"(637) Sprayer Tank - Rechargeable (2 In 1) 16ltr"
  },
 {
    img:img20,
    name:"(856) Engine Sprayer 767 - 20ltr"
  },
 {
    img:img21,
    name:"(884) Sprayer Tank 16ltr"
  },
 {
    img:img22,
    name:"(1232) Bottle Sprayer 10ltr"
  },
 {
    img:img23,
    name:"(1382) Bottle Sprayer 02ltr"
  },
 {
    img:img24,
    name:"(1383) Bottle Sprayer 05ltr"
  },
 {
    img:img25,
    name:"(1945) Sprayer Tank (Heavy) 16ltr"
  },
 {
    img:img26,
    name:"(3119) Bottle Sprayer-Rechargeable 16ltr"
  },
 {
    img:img27,
    name:"(2142) Tea plucking Machine (Gasoline)"
  },
 {
    img:img28,
    name:"(3141) Tea plucking machine (Electric)"
  },
  {
    img:img29,
    name:"(652) Submersible pump Qdx3-20-0.55 -1"
  },
 {
    img:img30,
    name:"(740) Automatic Pump Controller"
  },
 {
    img:img31,
    name:"(1231) Water pump Gasoline (4stroke)"
  },
 {
    img:img32,
    name:"(1818) SUBMERSIBLE WATER PUMP - 2"
  },
 {
    img:img33,
    name:"(2432)(2433) Water pump CPM-130 & 158 -1"
  },
 {
    img:img34,
    name:"(2435) Water pump Qb-60 -1"
  },
 {
    img:img35,
    name:"(2457) Submersible pump Qdx - 1"
  },
 {
    img:img36,
    name:"(2458) Submersible pump 50WQDJ15-13-1.5F - 2"
  },
 {
    img:img37,
    name:"(2679) Pump LSWQB-12 (DC-12V)"
  },
 {
    img:img38,
    name:"(2685)(1701) Water pump (Petrol start) Kerosene"
  },
 {
    img:img39,
    name:"(2954) Tube Well Pump 24v"
  },
 {
    img:img40,
    name:"(3016) Water Pressure Testing Pump"
  },
 {
    img:img41,
    name:"(6255)(6628) Water pump Gasoline (2stroke)"
  },
 {
    img:img42,
    name:"(6453) Water Pump-QB-60"
  },
 {
    img:img43,
    name:"TUBE WELL WATER PUMP"
  },
]

function Agriculture() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };

  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {agriculture.map((name, index) => (
              <a className="product-link" key={index}>
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={agriculture[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {agriculture[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default Agriculture
