import React from "react"
import Slider from "react-slick"
import $ from 'jquery'
// importing images
import img1 from "../../images/products/Hand Tools/Combination/(93) HACK SAW FRAME - VEIBER 12 ROUND HANDLE.jpg"
import img2 from "../../images/products/Hand Tools/Combination/(188) HAND PRUNING SAW - SAWORAI(GREEN HANDLE 01.jpg"
import img3 from "../../images/products/Hand Tools/Combination/(189) T-HANDLE WITH SOCKET WRENCH SET 11PCS 01.jpg"
import img4 from "../../images/products/Hand Tools/Combination/(293) HAND PRUNING SAW - SAWORAI (WITH KNIFE) 01.jpg"
import img5 from "../../images/products/Hand Tools/Combination/(386) OIL FILTER WRENCH - CHAIN TYPE 12 01.jpg"
import img6 from "../../images/products/Hand Tools/Combination/(387) OIL FILTER WRENCH - 3 JAW 01.jpg"
import img7 from "../../images/products/Hand Tools/Combination/(747) CUTTING PLIER - CHANG HONG 10 (LEFT) 01.jpg"
import img8 from "../../images/products/Hand Tools/Combination/(748) CUTTING PLIER - CHANG HONG 10 (RIGHT) 01.jpg"
import img9 from "../../images/products/Hand Tools/Combination/(760) BOX SOCKET SET - 12 17PCS (BLACK TOOL) 01.jpg"
import img10 from "../../images/products/Hand Tools/Combination/(1053) BOX SOCKET SET - 12 10PCS (CTW) 01.jpg"
import img11 from "../../images/products/Hand Tools/Combination/(1084) MONKEY PLIER - YAJUN TOOLS 01.jpg"
import img12 from "../../images/products/Hand Tools/Combination/(1298) LOCKING PLIER - FLAT TYPE 10 01.jpg"
import img13 from "../../images/products/Hand Tools/Combination/(1301) LOCKING PLIER - U TYPE 10 01.jpg"
import img14 from "../../images/products/Hand Tools/Combination/(1371) TORQUE WRENCH - 12 ADJUSTABLE 01.jpg"
import img15 from "../../images/products/Hand Tools/Combination/(1746) Hack Saw Frame Veiber (8013-2) 01.jpg"
import img16 from "../../images/products/Hand Tools/Combination/(1747) HACK SAW FRAME - VEIBER (8013-3) 01.jpg"
import img17 from "../../images/products/Hand Tools/Combination/(1748) HACK SAW FRAME - VEIBER (8013-5) 01.jpg"
import img18 from "../../images/products/Hand Tools/Combination/(1749) HACK SAW FRAME - VEIBER (8013-4) 01.jpg"
import img19 from "../../images/products/Hand Tools/Combination/(1750) BOX SOCKET SET - 1(22MM-41MM) RED BOX 01.jpg"
import img20 from "../../images/products/Hand Tools/Combination/(1776) RATCHET WRENCH - 17MMX19MM TIP HANDLE 01.jpg"
import img21 from "../../images/products/Hand Tools/Combination/(1958) RUBBER HAMMER-VEIBER 500G WOOD HANDLE 01.jpg"
import img22 from "../../images/products/Hand Tools/Combination/(2066) BOX SOCKET SET-1210PCS BLACK TOOL(RED) 01.jpg"
import img23 from "../../images/products/Hand Tools/Combination/(2116) TORX BIT SOCKET - 38 8PCS SET(HORUSDY) 01.jpg"
import img24 from "../../images/products/Hand Tools/Combination/(2425) BINDING TOOL - SEMI AUTOMATIC 01.jpg"
import img25 from "../../images/products/Hand Tools/Combination/(2478) BOX SOCKET SET-12 24PCS NICAL ZHONGXIN 01.jpg"
import img26 from "../../images/products/Hand Tools/Combination/(2555) BIN STORAGE RACK 36PCS (BLUE & ORANGE) 01.jpg"
import img27 from "../../images/products/Hand Tools/Combination/(2575) BOX SOCKET SET-SATAGOOD 1446PCS G10027 01.jpg"
import img28 from "../../images/products/Hand Tools/Combination/(2577) T-HANDLE 11PCS SETS (OAWE) 8MM-19MM 01.jpg"
import img29 from "../../images/products/Hand Tools/Combination/(2771) Diamond files.jpg"
import img30 from "../../images/products/Hand Tools/Combination/(2771) DIAMOND T.C.T FILES (4160) 10PCS PACK 01.jpg"
import img31 from "../../images/products/Hand Tools/Combination/(2881) HOLE WRENCH SET - L TYPE 5PCS (8MM-17MM) 01.jpg"
import img32 from "../../images/products/Hand Tools/Combination/(2965) RATCHET WRENCH SET - MULTI DRIVE 01.jpg"
import img33 from "../../images/products/Hand Tools/Combination/(2969) OIL FILTER WRENCH - CHAIN TYPE (BIG) 01.jpg"
import img34 from "../../images/products/Hand Tools/Combination/(2970) HALF DRIVE BOX SET HEX - 8PCS(4MM-14MM) 01.jpg"
import img35 from "../../images/products/Hand Tools/Combination/(2972) HALF DRIVE BOX SET M - 8PCS (M5-M14) 001.jpg"
import img36 from "../../images/products/Hand Tools/Combination/(3697) SAW SETTER HAND - 6 01.jpg"
import img37 from "../../images/products/Hand Tools/Combination/(4000) HACK SAW FRAME - POLAND 01.jpg"
import img38 from "../../images/products/Hand Tools/Combination/(4097) BOX SOCKET SET-12(24PCS)UT424SBM BLACK 01.jpg"
import img39 from "../../images/products/Hand Tools/Combination/(6231) Box Socket Set satagood 1X2 10pcs G10017 01.jpg"
import img40 from "../../images/products/Hand Tools/Combination/(6232) BOX SOCKET SET - 40PCS (SUPER TOOLS) 01.jpg"
import img41 from "../../images/products/Hand Tools/Combination/(6467) CUTTING PLIER 01.jpg"
import img42 from "../../images/products/Hand Tools/Combination/(6468) CUTTING PLIER - JIANG PING 10 (STEEL) 01.jpg"
import img43 from "../../images/products/Hand Tools/Combination/ADJUSTABLE WRENCH  VEIBER 01.jpg"
import img44 from "../../images/products/Hand Tools/Combination/ADJUSTABLE WRENCH 01.jpg"
import img45 from "../../images/products/Hand Tools/Combination/ALLEN KEY SETS-NORMAL LANG 01.jpg"
import img46 from "../../images/products/Hand Tools/Combination/ALLEN KEY SETS-NORMAL VEIBER 01.jpg"
import img47 from "../../images/products/Hand Tools/Combination/ALLEN KEY SETS-STAR LANG 01.jpg"
import img48 from "../../images/products/Hand Tools/Combination/ALUMINIUM LEVEL 01.jpg"
import img49 from "../../images/products/Hand Tools/Combination/ALUMINIUM SASH CLAMP SILVERLINE 01.jpg"
import img50 from "../../images/products/Hand Tools/Combination/BALL PEIN HAMMER-VEIBER 01.jpg"
import img51 from "../../images/products/Hand Tools/Combination/BOLT CUTTER Veiber 01.jpg"
import img52 from "../../images/products/Hand Tools/Combination/BOX SOCKET 1 (BLACK) 01.jpg"
import img53 from "../../images/products/Hand Tools/Combination/BOX SOCKET Black 1 and 0.75 01.jpg"
import img54 from "../../images/products/Hand Tools/Combination/BOX SOCKET SHORT 1X2 01.jpg"
import img55 from "../../images/products/Hand Tools/Combination/BRAKE PIPE WRENCH 01.jpg"
import img56 from "../../images/products/Hand Tools/Combination/CLAW HAMMER-VEIBER 01.jpg"
import img57 from "../../images/products/Hand Tools/Combination/DT-B BOX SOCKET 1 01.jpg"
import img58 from "../../images/products/Hand Tools/Combination/EXTENSION BAR - COMBINATION 01.jpg"
import img59 from "../../images/products/Hand Tools/Combination/G-CLAMP COMBINATION 01.jpg"
import img60 from "../../images/products/Hand Tools/Combination/GILBO CUTTER 01.jpg"
import img61 from "../../images/products/Hand Tools/Combination/HAND SAW (TOR WHEEL) 01.jpg"
import img62 from "../../images/products/Hand Tools/Combination/LOCKING PLIER - STRAIGHT 01.jpg"
import img63 from "../../images/products/Hand Tools/Combination/MACHINIST HAMMER-VEIBER 01.jpg"
import img64 from "../../images/products/Hand Tools/Combination/NOSE PLIER - VEIBER 01.jpg"
import img65 from "../../images/products/Hand Tools/Combination/PIPE WRENCH 01.jpg"
import img66 from "../../images/products/Hand Tools/Combination/PLIER - VEIBER 01.jpg"
import img67 from "../../images/products/Hand Tools/Combination/RATCHET WRENCH - VEIBER 01.jpg"
import img68 from "../../images/products/Hand Tools/Combination/REDUCER SOCKET - DONG TE 01.jpg"
import img69 from "../../images/products/Hand Tools/Combination/SLEDGE HAMMER - VEIBER 01.jpg"
import img70 from "../../images/products/Hand Tools/Combination/STONING HAMMER - VEIBER 01.jpg"
import img71 from "../../images/products/Hand Tools/Combination/T-HANDLE WRENCH 01.jpg"
import img72 from "../../images/products/Hand Tools/Combination/T-HANDLE WRENCH 02.jpg"
import img73 from "../../images/products/Hand Tools/Combination/VEIBER WRENCH 01.jpg"
import img74 from "../../images/products/Hand Tools/Combination/WRENCH SET - JASEN 01.jpg"
import img75 from "../../images/products/Hand Tools/Combination/WRENCH SET - MAXTOP 01.jpg"
import img76 from "../../images/products/Hand Tools/Combination/WRENCH SET - VEIBER 01.jpg"

import img77 from "../../images/products/Hand Tools/Screw Driver/(126) Screw Driver Set 7Pcs 01.jpg"
import img78 from "../../images/products/Hand Tools/Screw Driver/(1878) SCREW DRIVER-11PCS SET (SMALL 01.jpg"
import img79 from "../../images/products/Hand Tools/Screw Driver/(2271) UNIVERSAL SCREW DRIVER-11PCS WITH HEAD 01.jpg"
import img80 from "../../images/products/Hand Tools/Screw Driver/(2371) SCREW DRIVER SET - 7PCS (SEDY-94153) 01.jpg"
import img81 from "../../images/products/Hand Tools/Screw Driver/(2392) SCREW DRIVER 5MM (+-) 2IN1 VEIBER VB802 01.jpg"
import img82 from "../../images/products/Hand Tools/Screw Driver/(2393) SCREW DRIVER 8MM (+-) 2IN1 VEIBER VB801 01.jpg"
import img83 from "../../images/products/Hand Tools/Screw Driver/(3064) SCREW DRIVER-6PCS PRECISION (MINI) 91025 01.jpg"
import img84 from "../../images/products/Hand Tools/Screw Driver/(6364) RUBBER GRIP SCREW DRIVER 06 (-) 01.jpg"
import img85 from "../../images/products/Hand Tools/Screw Driver/(6367) Screw Deiver (+ -).jpg"
import img86 from "../../images/products/Hand Tools/Screw Driver/BLACK & YELLOW SCREW DRIVER 01.jpg"
import img87 from "../../images/products/Hand Tools/Screw Driver/GERMANY STYLE SCREW DRIVER 01.jpg"
import img88 from "../../images/products/Hand Tools/Screw Driver/RUBBER GRIP BLUE&ASH SCREW DRIVER 01.jpg"
import img89 from "../../images/products/Hand Tools/Screw Driver/RUBBER GRIP BLUE&ASH SCREW DRIVER0 01.jpg"

import img90 from "../../images/products/Hand Tools/Tool Box/(2366) Tool Box - 187pcs (Aluminium Case).jpg"
import img91 from "../../images/products/Hand Tools/Tool Box/(2367) TOOL BOX - 85PCS 14 01.jpg"
import img92 from "../../images/products/Hand Tools/Tool Box/(2416) TOOL BOX - 33CM (METAL) 3 TRAYS 01.jpg"
import img93 from "../../images/products/Hand Tools/Tool Box/TOOLS BOX (DOUBLE HANDLE) VEIBER 01.jpg"
import img94 from "../../images/products/Hand Tools/Tool Box/TOOLS BOX (SINGLE HANDLE) 01.jpg"
import ContactUsModal from "../ContactUsModal"



const compressor = [
  {
    img:img1,
    name:"HACK SAW FRAME - VEIBER 12 ROUND HANDLE"
  },
{
    img:img2,
    name:"HAND PRUNING SAW - SAWORAI(GREEN HANDLE"
  },
{
    img:img3,
    name:"T-HANDLE WITH SOCKET WRENCH SET 11PCS"
  },
{
    img:img4,
    name:"HAND PRUNING SAW - SAWORAI (WITH KNIFE)"
  },
{
    img:img5,
    name:"OIL FILTER WRENCH - CHAIN TYPE 12"
  },{
    img:img6,
    name:"OIL FILTER WRENCH - 3 JAW"
  },
{
    img:img7,
    name:"CUTTING PLIER - CHANG HONG 10 (LEFT)"
  },
{
    img:img8,
    name:"CUTTING PLIER - CHANG HONG 10 (RIGHT)"
  },{
    img:img9,
    name:"BOX SOCKET SET - 12 17PCS (BLACK TOOL)"
  },
{
    img:img10,
    name:"BOX SOCKET SET - 12 10PCS (CTW)"
  },
{
    img:img11,
    name:"MONKEY PLIER - YAJUN TOOLS"
  },
{
    img:img12,
    name:"LOCKING PLIER - FLAT TYPE"
  },
{
    img:img13,
    name:"LOCKING PLIER - U TYPE 10"
  },
{
    img:img14,
    name:"TORQUE WRENCH - 12 ADJUSTABLE"
  },
{
    img:img15,
    name:"Hack Saw Frame Veiber (8013-2)"
  },{
    img:img16,
    name:"HACK SAW FRAME - VEIBER (8013-3)"
  },
{
    img:img17,
    name:"HACK SAW FRAME - VEIBER (8013-5)"
  },
{
    img:img18,
    name:"HACK SAW FRAME - VEIBER (8013-4)"
  },{
    img:img19,
    name:"BOX SOCKET SET - 1(22MM-41MM) RED BOX"
  },
{
    img:img20,
    name:"RATCHET WRENCH - 17MMX19MM TIP HANDLE"
  },
{
    img:img21,
    name:"RUBBER HAMMER-VEIBER 500G WOOD HANDLE"
  },
{
    img:img22,
    name:"BOX SOCKET SET-1210PCS BLACK TOOL(RED)"
  },
{
    img:img23,
    name:"TORX BIT SOCKET - 38 8PCS SET(HORUSDY)"
  },
{
    img:img24,
    name:"BINDING TOOL - SEMI AUTOMATIC"
  },
{
    img:img25,
    name:"BOX SOCKET SET-12 24PCS NICAL ZHONGXIN"
  },{
    img:img26,
    name:"BIN STORAGE RACK 36PCS (BLUE & ORANGE)"
  },
{
    img:img27,
    name:"BOX SOCKET SET-SATAGOOD 1446PCS G10027"
  },
{
    img:img28,
    name:"T-HANDLE 11PCS SETS (OAWE) 8MM-19MM"
  },{
    img:img29,
    name:"Diamond files"
  },
{
    img:img30,
    name:"DIAMOND T.C.T FILES (4160) 10PCS PACK"
  },
{
    img:img31,
    name:"HOLE WRENCH SET - L TYPE 5PCS (8MM-17MM)"
  },
{
    img:img32,
    name:"RATCHET WRENCH SET - MULTI DRIVE"
  },
{
    img:img33,
    name:"OIL FILTER WRENCH - CHAIN TYPE (BIG)"
  },
{
    img:img34,
    name:"HALF DRIVE BOX SET HEX - 8PCS(4MM-14MM)"
  },
{
    img:img35,
    name:"HALF DRIVE BOX SET M - 8PCS (M5-M14)"
  },{
    img:img36,
    name:"SAW SETTER HAND"
  },
{
    img:img37,
    name:"HACK SAW FRAME - POLAND"
  },
{
    img:img38,
    name:"BOX SOCKET SET-12(24PCS)UT424SBM BLACK"
  },{
    img:img39,
    name:"Box Socket Set satagood 1X2 10pcs G10017"
  },
{
    img:img40,
    name:"BOX SOCKET SET - 40PCS (SUPER TOOLS)"
  },
{
    img:img41,
    name:"CUTTING PLIER"
  },
{
    img:img42,
    name:"CUTTING PLIER - JIANG PING 10"
  },
{
    img:img43,
    name:"ADJUSTABLE WRENCH  VEIBER"
  },
{
    img:img44,
    name:"ADJUSTABLE WRENCH"
  },
{
    img:img45,
    name:"ALLEN KEY SETS-NORMAL LANG"
  },{
    img:img46,
    name:"ALLEN KEY SETS-NORMAL VEIBER"
  },
{
    img:img47,
    name:"ALLEN KEY SETS-STAR LANG"
  },
{
    img:img48,
    name:"ALUMINIUM LEVEL"
  },{
    img:img49,
    name:"ALUMINIUM SASH CLAMP SILVERLINE"
  },
{
    img:img50,
    name:"BALL PEIN HAMMER-VEIBER"
  },
{
    img:img51,
    name:"BOLT CUTTER Veiber"
  },
{
    img:img52,
    name:"BOX SOCKET 1 (BLACK)"
  },
{
    img:img53,
    name:"BOX SOCKET Black 1 and 0.75"
  },
{
    img:img54,
    name:"BOX SOCKET SHORT 1X2"
  },
{
    img:img55,
    name:"BRAKE PIPE WRENCH"
  },{
    img:img56,
    name:"CLAW HAMMER-VEIBER"
  },
{
    img:img57,
    name:"DT-B BOX SOCKET"
  },
{
    img:img58,
    name:"EXTENSION BAR - COMBINATION"
  },{
    img:img59,
    name:"G-CLAMP COMBINATION"
  },
{
    img:img60,
    name:"GILBO CUTTER"
  },
{
    img:img61,
    name:"HAND SAW (TOR WHEEL)"
  },
{
    img:img62,
    name:"LOCKING PLIER - STRAIGHT"
  },
{
    img:img63,
    name:"MACHINIST HAMMER-VEIBER"
  },
{
    img:img64,
    name:"NOSE PLIER - VEIBER"
  },
{
    img:img65,
    name:"PIPE WRENCH"
  },{
    img:img66,
    name:"PLIER - VEIBER"
  },
{
    img:img67,
    name:"RATCHET WRENCH - VEIBER"
  },
{
    img:img68,
    name:"REDUCER SOCKET - DONG TE"
  },{
    img:img69,
    name:"SLEDGE HAMMER - VEIBER"
  },
{
    img:img70,
    name:"STONING HAMMER - VEIBER"
  },
{
    img:img71,
    name:"T-HANDLE WRENCH"
  },
{
    img:img72,
    name:"T-HANDLE WRENCH"
  },
{
    img:img73,
    name:"VEIBER WRENCH"
  },
{
    img:img74,
    name:"WRENCH SET - JASEN"
  },
{
    img:img75,
    name:"WRENCH SET - MAXTOP"
  },{
    img:img76,
    name:"WRENCH SET - VEIBER"
  },
  {
    img:img77,
    name:"Screw Driver Set 7Pcs"
  },
{
    img:img78,
    name:"SCREW DRIVER-11PCS SET"
  },
{
    img:img79,
    name:"UNIVERSAL SCREW DRIVER-11PCS WITH HEAD"
  },
{
    img:img80,
    name:"SCREW DRIVER SET - 7PCS (SEDY-94153)"
  },
{
    img:img81,
    name:"SCREW DRIVER 5MM (+-) 2IN1 VEIBER VB802"
  },{
    img:img82,
    name:"SCREW DRIVER 8MM (+-) 2IN1 VEIBER VB801"
  },
{
    img:img83,
    name:"SCREW DRIVER-6PCS PRECISION (MINI) 91025"
  },
{
    img:img84,
    name:"RUBBER GRIP SCREW DRIVER"
  },{
    img:img85,
    name:"Screw Deiver"
  },
{
    img:img86,
    name:"BLACK & YELLOW SCREW DRIVER"
  },
{
    img:img87,
    name:"GERMANY STYLE SCREW DRIVER"
  },
{
    img:img88,
    name:"RUBBER GRIP BLUE&ASH SCREW DRIVER"
  },
{
    img:img89,
    name:"RUBBER GRIP BLUE&ASH SCREW DRIVER0"
  },
  ,{
    img:img90,
    name:"Tool Box - 187pcs (Aluminium Case)"
  },
{
    img:img91,
    name:"TOOL BOX - 85PCS"
  },
{
    img:img92,
    name:"TOOL BOX - 33CM (METAL) 3 TRAYS"
  },
{
    img:img93,
    name:"BOX (DOUBLE HANDLE) VEIBER"
  },
{
    img:img94,
    name:"BOX (SINGLE HANDLE)"
  },

]

function CuttingRotary() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default CuttingRotary
