import React from "react"
import Slider from "react-slick"
// import {Link} from "gatsby"
// importing images
import img1 from "../../images/products/Compressors & Air Tools/Air Com/(1562) Air Compressor Oil Free - 25 Ltr.jpg"
import img2 from "../../images/products/Compressors & Air Tools/Air Com/(1563) Air Compressor Oil Free - 50 Ltr.jpg"
import img3 from "../../images/products/Compressors & Air Tools/Air Com/(2477)(6403)(6687)(1702) Air Compressor Direct couple.jpg"
import img4 from "../../images/products/Compressors & Air Tools//Air Com/(6312) Air Compressor Direct couple - 25 Ltr.jpg"
import img5 from "../../images/products/Compressors & Air Tools/Air Com/(6313) Air Compressor Direct couple - 40 Ltr.jpg"

import img6 from "../../images/products/Compressors & Air Tools/Air Tools/(1299) Pneumatic impack wrench 34.jpg"
import img7 from "../../images/products/Compressors & Air Tools/Air Tools/(1314) Cement Plaster sprayer (Air).jpg"
import img8 from "../../images/products/Compressors & Air Tools/Air Tools/(1603)(1054) Portable cable oil tank.jpg"
import img9 from "../../images/products/Compressors & Air Tools/Air Tools/(1785) Pneumatic Pop Rivet Gun - Industrial Type.jpg"
import img10 from "../../images/products/Compressors & Air Tools/Air Tools/(1788) Pneumatic nail gun F-50 (Okeway).jpg"
import img11 from "../../images/products/Compressors & Air Tools/Air Tools/(1862) Pneumatic impack wrench 12.jpg"
import img12 from "../../images/products/Compressors & Air Tools/Air Tools/(1966) Pneumatic impack wrench 1.jpg"
import img13 from "../../images/products/Compressors & Air Tools/Air Tools/(1986) Pneumatic nail gun F-32 (Meite).jpg"
import img14 from "../../images/products/Compressors & Air Tools/Air Tools/(1987) Pneumatic stepler gun 1013J - Meite.jpg"
import img15 from "../../images/products/Compressors & Air Tools/Air Tools/(2033) Pneumatic nail gun F-50 ZSK.jpg"
import img16 from "../../images/products/Compressors & Air Tools/Air Tools/(2036) Pneumatic Nail Gun SF-5040 (U Type).jpg"
import img17 from "../../images/products/Compressors & Air Tools/Air Tools/(2068) Pneumatic screwdriver- 305HA (Professional).jpg"
import img18 from "../../images/products/Compressors & Air Tools/Air Tools/(2203) Nail Gun F-30 (Electric).jpg"
import img19 from "../../images/products/Compressors & Air Tools/Air Tools/(2613) Pneumatic random sander 5.jpg"
import img20 from "../../images/products/Compressors & Air Tools/Air Tools/(2641) Pneumatic coil nail gun CN70.jpg"
import img21 from "../../images/products/Compressors & Air Tools/Air Tools/(2729) Pneumatic screw drivert.jpg"
import img22 from "../../images/products/Compressors & Air Tools/Air Tools/(2783) Pneumatic coil nail gun CN55.jpg"
import img23 from "../../images/products/Compressors & Air Tools/Air Tools/(2784) Air Duster Gun - 215mm 14 (BG215-01).jpg"
import img24 from "../../images/products/Compressors & Air Tools/Air Tools/(2785) Pneumatic impack wrench 12 - Rongpeng.jpg"
import img25 from "../../images/products/Compressors & Air Tools/Air Tools/(2786) Pneumatic angle grinder - RP7319.jpg"
import img26 from "../../images/products/Compressors & Air Tools/Air Tools/(2878) Pneumatic drill (10mm).jpg"
import img27 from "../../images/products/Compressors & Air Tools/Air Tools/(2880) Pneumatic drill - Straight (10mm).jpg"
import img28 from "../../images/products/Compressors & Air Tools/Air Tools/(3015) Air Suction dent puller.jpg"
import img29 from "../../images/products/Compressors & Air Tools/Air Tools/(3170) Pneumatic nail gun F-50E (Meite).jpg"
import img30 from "../../images/products/Compressors & Air Tools/Air Tools/(6718) Pneumatic impack wrench 12.jpg"
import img31 from "../../images/products/Compressors & Air Tools/Air Tools/(6132) Pneumatic die grinder (10pcs grinding bits).jpg"
import img32 from "../../images/products/Compressors & Air Tools/Air Tools/(6317) Pneumatic Pop Rivet Gun.jpg"
import img33 from "../../images/products/Compressors & Air Tools/Air Tools/(6359) Pneumatic impack wrench 1.jpg"
import img34 from "../../images/products/Compressors & Air Tools/Air Tools/(6362) Mini Inflator.jpeg"
import img35 from "../../images/products/Compressors & Air Tools/Air Tools/(6457) Pneumatic nail gun F-30.jpg"
import img36 from "../../images/products/Compressors & Air Tools/Air Tools/(6458) Pneumatic Nail Gun T-50.jpg"
import img37 from "../../images/products/Compressors & Air Tools/Air Tools/(6543) Pneumatic stepler gun 1013J - ZSK.jpg"
import img38 from "../../images/products/Compressors & Air Tools/Air Tools/(6643) Pneumatic Nail Gun ST-64 (Zsk).jpg"

import $ from 'jquery'
import ContactUsModal from "../ContactUsModal"

const compressor = [  
  {
    img:img1,
    name:"(1562) Air Compressor Oil Free - 25 Ltr"
  },
  {
    img:img2,
    name:"(1563) Air Compressor Oil Free - 50 Ltr"
  },
  {
    img:img3,
    name:"(2477)(6403)(6687)(1702) Air Compressor Direct couple"
  },
  {
    img:img4,
    name:"(6312) Air Compressor Direct couple - 25 Ltr"
  },
  {
    img:img5,
    name:"(6313) Air Compressor Direct couple - 40 Ltr"
  },{
    img:img6,
    name:"(1299) Pneumatic impack wrench 34"
  },
  {
    img:img7,
    name:"(1314) Cement Plaster sprayer (Air)"
  },
  {
    img:img8,
    name:"(1603)(1054) Portable cable oil tank"
  },{
    img:img9,
    name:"(1785) Pneumatic Pop Rivet Gun - Industrial Type"
  },
  {
    img:img10,
    name:"(1788) Pneumatic nail gun F-50 (Okeway)"
  },
  {
    img:img11,
    name:"(1862) Pneumatic impack wrench 12"
  },
  {
    img:img12,
    name:"(1966) Pneumatic impack wrench 1"
  },
  {
    img:img13,
    name:"(1986) Pneumatic nail gun F-32 (Meite)"
  },
  {
    img:img14,
    name:"(1987) Pneumatic stepler gun 1013J - Meite"
  },
  {
    img:img15,
    name:"(2033) Pneumatic nail gun F-50 ZSK"
  },{
    img:img16,
    name:"(2036) Pneumatic Nail Gun SF-5040 (U Type)"
  },
  {
    img:img17,
    name:"(2068) Pneumatic screwdriver- 305HA (Professional)"
  },
  {
    img:img18,
    name:"(2203) Nail Gun F-30 (Electric)"
  },{
    img:img19,
    name:"(2613) Pneumatic random sander 5"
  },
  {
    img:img20,
    name:"(2641) Pneumatic coil nail gun CN70"
  },
// 30start
  {
    img:img21,
    name:"(2729) Pneumatic screw drivert"
  },
  {
    img:img22,
    name:"(2783) Pneumatic coil nail gun CN55"
  },
  {
    img:img23,
    name:"(2784) Air Duster Gun - 215mm 14 (BG215-01)"
  },
  {
    img:img24,
    name:"(2785) Pneumatic impack wrench 12 - Rongpeng"
  },
  {
    img:img25,
    name:"(2786) Pneumatic angle grinder - RP7319"
  },{
    img:img26,
    name:"(2878) Pneumatic drill (10mm)"
  },
  {
    img:img27,
    name:"(2880) Pneumatic drill - Straight (10mm)"
  },
  {
    img:img28,
    name:"(3015) Air Suction dent puller"
  },{
    img:img29,
    name:"(3170) Pneumatic nail gun F-50E (Meite)"
  },
  {
    img:img30,
    name:"(6132) Pneumatic die grinder (10pcs grinding bits)"
  },
// 40start
  {
    img:img31,
    name:"(6132) Pneumatic die grinder (10pcs grinding bits)"
  },
  {
    img:img32,
    name:"(6317) Pneumatic Pop Rivet Gun"
  },
  {
    img:img33,
    name:"(6359) Pneumatic impack wrench 1"
  },
  {
    img:img34,
    name:"(6362) Mini Inflator"
  },
  {
    img:img35,
    name:"(6457) Pneumatic nail gun F-30"
  },{
    img:img36,
    name:"(6458) Pneumatic Nail Gun T-50"
  },
  {
    img:img37,
    name:"(6543) Pneumatic stepler gun 1013J - ZSK"
  },
  {
    img:img38,
    name:"(6643) Pneumatic Nail Gun ST-64 (Zsk)"
  }
]

function AirCompressor() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {compressor.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={compressor[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {compressor[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                    <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div> 
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default AirCompressor
