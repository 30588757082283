import React from "react"
import Slider from "react-slick"
import $ from 'jquery'
// importing images
import img1 from "/src/images/products/Wood Working/Router Bit/(317) SPEED CUT 1 R BIT (R.O.B) 1X4X5X8 01.jpg"
import img2 from "/src/images/products/Wood Working/Router Bit/(347) SPEED CUT 1 R BIT PANNELBIT12X1 12W1 01.jpg"
import img3 from "/src/images/products/Wood Working/Router Bit/(349) SPEED CUT 1 R BIT (J.B) T.T 12 X 14 copy 01.jpg"
import img4 from "/src/images/products/Wood Working/Router Bit/(818) SPEED CUT 1 R BIT PANNEL BIT12X1 [W1] copy 01.jpg"
import img5 from "/src/images/products/Wood Working/Router Bit/(819) SPEED CUT 1 R BIT PANNEL BIT12X1 [W2] copy 0 1.jpg"
import img6 from "/src/images/products/Wood Working/Router Bit/(820) SPEED CUT 1 R BIT PANNEL BIT12X1 [W3] copy 0 1.jpg"
import img7 from "/src/images/products/Wood Working/Router Bit/(821) SPEED CUT 1 R BIT PANNEL BIT12X1 [W6] copy 01.jpg"
import img8 from "/src/images/products/Wood Working/Router Bit/(822) SPEED CUT 1 R BIT PANNEL BIT12X1 [W7] 01.jpg"
import img9 from "/src/images/products/Wood Working/Router Bit/(995) SPEED CUT 1 R BIT (C.M.B) 12 X 34 01.jpg"
import img10 from "/src/images/products/Wood Working/Router Bit/(1020) SPEED CUT 1 R BIT (J.B) 12X1 12-2PCS 01.jpg"
import img11 from "/src/images/products/Wood Working/Router Bit/(1800) SPEED CUT 1 R BIT PANNELBIT12X1 12W7  01.jpg"
import img12 from "/src/images/products/Wood Working/Router Bit/(1801) SPEED CUT 1 R BIT PANNELBIT12X1 12W6 copy 01.jpg"
import img13 from "/src/images/products/Wood Working/Router Bit/(1802) SPEED CUT 1 R BIT PANNELBIT12X1 12W3  01.jpg"
import img14 from "/src/images/products/Wood Working/Router Bit/(1803) SPEED CUT 1 R BIT PANNELBIT12X1 12W2 01.jpg"
import img15 from "/src/images/products/Wood Working/Router Bit/(2801) SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E2) 01.jpg"
import img16 from "/src/images/products/Wood Working/Router Bit/(2802) SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E7) 01.jpg"
import img17 from "/src/images/products/Wood Working/Router Bit/(2803) SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E6) 01.jpg"
import img18 from "/src/images/products/Wood Working/Router Bit/(2928) SPEED CUT 1 R BIT (J.B)IG 12X14-2PCS copy 0 1.jpg"
import img19 from "/src/images/products/Wood Working/Router Bit/(2929) SPEED CUT 1 R BIT PANNEL (C) 12X1 12 copy 01.jpg"
import img20 from "/src/images/products/Wood Working/Router Bit/(4792) SPEED CUT 1 R BIT (J.B)SINGLE12X1 14 copy 01.jpg"
import img21 from "/src/images/products/Wood Working/Router Bit/(6180) SPEED CUT 1 R BIT PANNEL (T) 12X1 12 copy 01.jpg"
import img22 from "/src/images/products/Wood Working/Router Bit/(6184) SPEED CUT 1 R BIT (D.B) 14 X 58 copy 01.jpg"
import img23 from "/src/images/products/Wood Working/Router Bit/(6575) SPEED CUT 1 R BIT (M.B.B) 12X 1 716  01.jpg"
import img24 from "/src/images/products/Wood Working/Router Bit/(6576) SPEED CUT 1 R BIT (HANDRAIL)12X 1 34 copy 01.jpg"
import img25 from "/src/images/products/Wood Working/Router Bit/KRALL R BIT B.B copy 01.jpg"
import img26 from "/src/images/products/Wood Working/Router Bit/KRALL R BIT SB copy 01.jpg"
import img27 from "/src/images/products/Wood Working/Router Bit/KRALL R BIT TRIM BIT copy 01.jpg"
import img28 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (B.B)  01.jpg"
import img29 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (C.B) copy 01.jpg"
import img30 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (C.C.B)  01.jpg"
import img31 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (C.L.B) 01.jpg"
import img32 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (C.O.B) copy 01.jpg"
import img33 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (C.V.B) copy 01.jpg"
import img34 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (CCBB) 01.jpg"
import img35 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (D.B.B) copy 01.jpg"
import img36 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (H.C.B copy 01.jpg"
import img37 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (H.N.B) copy 001.jpg"
import img38 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (H.S.C) copy 01.jpg"
import img39 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (I.S.B) copy 01.jpg"
import img40 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (OED) copy 01.jpg"
import img41 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (R.C.B) copy 01.jpg"
import img42 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (RC)PANNEL copy 01.jpg"
import img43 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (SB) copy 01.jpg"
import img44 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (T.B) copy 01.jpg"
import img45 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (T.T) copy 01.jpg"
import img46 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (TRIM) copy 01.jpg"
import img47 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (U) copy 01.jpg"
import img48 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (V) BTYPE copy 01.jpg"
import img49 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (V) copy 01.jpg"
import img50 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (W.BB) 01.jpg"
import img51 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (W.E.B) copy 01.jpg"
import img52 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (W.E.E.B) copy 01.jpg"
import img53 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (WB) copy 01.jpg"
import img54 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT (WB1) copy 01.jpg"
import img55 from "/src/images/products/Wood Working/Router Bit/SPEED CUT 1 R BIT 45 CHAMFER copy 01.jpg"
import img56 from "/src/images/products/Wood Working/Router Bit/TRIM BIT R BIT copy 01.jpg"

import img57 from "/src/images/products/Wood Working/Wood Cutting/MOULDING PANEL CUTTER (RAISED) 01.jpg"
import img58 from "/src/images/products/Wood Working/Wood Cutting/WOOD CUTTING GROOVE CUTTER 01.jpg"

import img59 from "/src/images/products/Wood Working/Wood Drill Bit/WOOD SQUARE DRILL BIT -  KUDIMBI 01.jpg"

import img60 from "/src/images/products/Wood Working/Wood Working/(1959) Angle Cutting Saw-550m.jpg"
import img61 from "/src/images/products/Wood Working/Wood Working/(2227) Scroll Saw Machine (Jf1601).jpg"
import img62 from "/src/images/products/Wood Working/Wood Working/(2228)(2736) Band Saw Machine.jpg"
import img63 from "/src/images/products/Wood Working/Wood Working/(2229) Miter Saw Sliding Machine (Adjustable).jpg"
import img64 from "/src/images/products/Wood Working/Wood Working/(2723) Table Saw 10 (72558e).jpg"
import img65 from "/src/images/products/Wood Working/Wood Working/(2788) Bench Planner Machine (Mb-120 150).jpg"
import ContactUsModal from "../ContactUsModal"



const agriculture = [
  {
    img:img1,
    name:"SPEED CUT 1 R BIT (R.O.B) 1X4X5X8"
  },
  {
    img:img2,
    name:"SPEED CUT 1 R BIT PANNELBIT12X1 12W1"
  },
  {
    img:img3,
    name:"SPEED CUT 1 R BIT (J.B) T.T 12 X 14"
  },
  {
    img:img4,
    name:"SPEED CUT 1 R BIT PANNEL BIT12X1"
  },
  {
    img:img5,
    name:"SPEED CUT 1 R BIT PANNEL BIT12X1 [W2]"
  },
  {
    img:img6,
    name:"SPEED CUT 1 R BIT PANNEL BIT12X1 [W3]"
  },

  {
    img:img7,
    name:"SPEED CUT 1 R BIT PANNEL BIT12X1 [W6]"
  },
  {
    img:img8,
    name:"SPEED CUT 1 R BIT PANNEL BIT12X1 [W7]"
  },

  {
    img:img9,
    name:"SPEED CUT 1 R BIT (C.M.B) 12 X 34"
  },
  {
    img:img10,
    name:"SPEED CUT 1 R BIT (J.B) 12X1 12-2PCS"
  },
  {
    img:img11,
    name:"SPEED CUT 1 R BIT PANNELBIT12X1 12W7"
  },
  {
    img:img12,
    name:"SPEED CUT 1 R BIT PANNELBIT12X1 12W6"
  },
  {
    img:img13,
    name:"SPEED CUT 1 R BIT PANNELBIT12X1 12W3"
  },

  {
    img:img14,
    name:"SPEED CUT 1 R BIT PANNELBIT12X1 12W2"
  },
  {
    img:img15,
    name:"SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E2)"
  },
  {
    img:img16,
    name:"SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E7)"
  },

  {
    img:img17,
    name:"SPEED CUT 1 R BIT PANNEL BIT 1 2 X 2 (E6)"
  },
  {
    img:img18,
    name:"SPEED CUT 1 R BIT (J.B)IG 12X14-2PCS"
  },
  {
    img:img19,
    name:"SPEED CUT 1 R BIT PANNEL (C) 12X1 12"
  },
 {
    img:img20,
    name:"SPEED CUT 1 R BIT (J.B)SINGLE12X1 14"
  },
 {
    img:img21,
    name:"SPEED CUT 1 R BIT PANNEL (T) 12X1 12"
  },
 {
    img:img22,
    name:"SPEED CUT 1 R BIT (D.B) 14 X 58"
  },
 {
    img:img23,
    name:"SPEED CUT 1 R BIT (M.B.B)"
  },
 {
    img:img24,
    name:"SPEED CUT 1 R BIT (HANDRAIL)12X 1 34"
  },
 {
    img:img25,
    name:"KRALL R BIT B.B"
  },
 {
    img:img26,
    name:"KRALL R BIT SB"
  },
 {
    img:img27,
    name:"KRALL R BIT TRIM BIT"
  },
 {
    img:img28,
    name:"SPEED CUT 1 R BIT (B.B)"
  },
  {
    img:img29,
    name:"SPEED CUT 1 R BIT (C.B)"
  },
 {
    img:img30,
    name:"SPEED CUT 1 R BIT (C.C.B)"
  },
 {
    img:img31,
    name:"SPEED CUT 1 R BIT (C.L.B)"
  },
 {
    img:img32,
    name:"SPEED CUT 1 R BIT (C.O.B)"
  },
 {
    img:img33,
    name:"SPEED CUT 1 R BIT (C.V.B)"
  },
 {
    img:img34,
    name:"SPEED CUT 1 R BIT (CCBB)"
  },
 {
    img:img35,
    name:"SPEED CUT 1 R BIT (D.B.B)"
  },
 {
    img:img36,
    name:"SPEED CUT 1 R BIT (H.C.B)"
  },
 {
    img:img37,
    name:"SPEED CUT 1 R BIT (H.N.B)"
  },
 {
    img:img38,
    name:"SPEED CUT 1 R BIT (H.S.C)"
  },
 {
    img:img39,
    name:"SPEED CUT 1 R BIT (I.S.B)"
  },
 {
    img:img40,
    name:"SPEED CUT 1 R BIT (OED)"
  },
 {
    img:img41,
    name:"SPEED CUT 1 R BIT (R.C.B)"
  },
 {
    img:img42,
    name:"SPEED CUT 1 R BIT (RC)PANNEL"
  },
 {
    img:img43,
    name:"SPEED CUT 1 R BIT (SB)"
  },
 {
    img:img44,
    name:"SPEED CUT 1 R BIT (T.B)"
  },
 {
    img:img45,
    name:"SPEED CUT 1 R BIT (T.T)"
  },
 {
    img:img46,
    name:"SPEED CUT 1 R BIT (TRIM)"
  },
 {
    img:img47,
    name:"SPEED CUT 1 R BIT (U)"
  },
 {
    img:img48,
    name:"SPEED CUT 1 R BIT (V) BTYPE"
  },
 {
    img:img49,
    name:"SPEED CUT 1 R BIT (V)"
  },
 {
    img:img50,
    name:"SPEED CUT 1 R BIT (W.BB)"
  },
 {
    img:img51,
    name:"SPEED CUT 1 R BIT (W.E.B)"
  },
 {
    img:img52,
    name:"SPEED CUT 1 R BIT (W.E.E.B)"
  },
 {
    img:img53,
    name:"SPEED CUT 1 R BIT (WB)"
  },
 {
    img:img54,
    name:"SPEED CUT 1 R BIT (WB1)"
  },
 {
    img:img55,
    name:"SPEED CUT 1 R BIT 45 CHAMFER"
  },
 {
    img:img56,
    name:"TRIM BIT R BIT"
  },
  {
    img:img57,
    name:"MOULDING PANEL CUTTER (RAISED)"
  },
  {
    img:img58,
    name:"WOOD CUTTING GROOVE CUTTER"
  },
  {
    img:img59,
    name:"WOOD SQUARE DRILL BIT -  KUDIMBI"
  },
  {
    img:img60,
    name:"Angle Cutting Saw-550m"
  },
  {
    img:img61,
    name:"Scroll Saw Machine (Jf1601)"
  },
  {
    img:img62,
    name:"Band Saw Machine"
  },
  {
    img:img63,
    name:"Miter Saw Sliding Machine (Adjustable)"
  },
  {
    img:img64,
    name:"Table Saw 10 (72558e)"
  },
  {
    img:img65,
    name:"Bench Planner Machine (Mb-120 150)"
  },
]

function Agriculture() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  const openModal = ()=> {
    $('#productModal').modal('show')
  }
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {agriculture.map((name, index) => (
              <a className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={agriculture[index].img} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    {agriculture[index].name}
                  </p>
                  <div className="product-btn d-flex flex-column">
                    {/* <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link> */}
                     <a onClick={()=>{openModal()}}  className="text-uppercase link_hover mr-0 ml-0 p-2">
                      inquiry
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <ContactUsModal/>
      </div>
    </>
  );

  // functions start here

}

export default Agriculture
